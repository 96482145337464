<template>
  <div
    class="class-hour-management flex items-center flex-col"
    style="display: flex; align-items: center; flex-direction: column"
  >
    <p class="title">{{ content.title }}</p>
    <p class="desc">
      {{ content.desc }}
    </p>
    <img :src="content.coverImg" class="w-0" />

    <div class="features">
      <div
        class="feature wow animate__animated animate__fadeInUp"
        v-for="(item, index) in content.features"
        :key="index"
        :data-wow-duration="index == 1 ? '3s' : index == 2 ? '6s' : '1s'"
      >
        <p>
          <!-- <svg-icon icon-class="color-course-type" /> -->
          <v-img :src="item.icon" />
        </p>
        <p class="card-title wow animate__animated animate__fadeInUp">{{ item.title }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  mounted() {
    new this.$wow.WOW().init();
  },
};
</script>

<style lang="scss" scoped>
// * {
//   font-family: MicrosoftYaHei, MicrosoftYaHei !important;
// }
@media (max-width: 640px) {
  .class-hour-management {
    background: #fff;
    padding-top: 20px;
    padding-bottom: 50px;
    text-align: center;
    .title {
      color: #2d3f50;
      font-size: 30px !important;
      // font-family: MicrosoftYaHei, MicrosoftYaHei !important;
    }

    .desc {
      padding: 0 50px;
      color: #333333;
      font-family: MicrosoftYaHei;
      font-size: 20px;
      text-align: center;
    }
    .card-title {
      font-size: 18px;
    }

    img {
      // width: 901px;
      // height: 718px;
      width: 100%;
      // height: auto;
      object-fit: contain;
      margin-top: 20px;
    }

    .features {
      display: grid;
      grid-gap: 30px 20px;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(1fr, 4);
      padding: 0 30px;

      .feature {
        @apply flex items-center;
        p {
          margin-bottom: 0;
        }

        // height: 73px;
        padding: 20px 10px;
        border-radius: 10px;
        background: #fff;
        box-shadow: 0 5px 8px 0 rgb(0 0 0 / 20%);
        color: #666;
        font-family: MicrosoftYaHei;
        font-size: 16px;
        display: flex;
        align-items: center;

        .v-image {
          width: 52px;
          height: 52px;
          margin-right: 20px;
          margin-left: 15px;
        }
      }
    }
  }
}

@media (min-width: 640px) {
  .class-hour-management {
    position: relative;
    height: 900px;
    margin-top: 65px;

    .title {
      color: #2d3f50;
      // font-family: MicrosoftYaHei, MicrosoftYaHei !important;
      font-size: 50px !important;
    }

    .desc {
      width: 511px;
      margin-top: 10px;
      color: #333333;
      font-family: MicrosoftYaHei;
      font-size: 26px;
      text-align: center;
    }
    .card-title {
      font-size: 20px;
    }
    img {
      // width: 901px;
      height: 718px;
      width: 100%;
      // height: auto;
      object-fit: contain;
      margin-top: 20px;
    }

    .features {
      // display: grid;
      // position: absolute;
      // bottom: 150px;
      // grid-gap: 30px 20px;
      // grid-template-columns: 1fr 1fr;
      // grid-template-rows: 1fr 1fr;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 1300px;

      .feature {
        @apply flex items-center;
        p {
          margin-bottom: 0;
        }
        height: 124px;
        width: 400px;
        border-radius: 10px;
        background: #fff;
        box-shadow: 0 5px 8px 0 rgb(0 0 0 / 20%);
        color: #666;
        font-family: MicrosoftYaHei;
        font-size: 20px;
        // padding: 20px;
        display: flex;
        align-items: center;
        padding-right: 30px;

        .v-image {
          width: 70px;
          height: 70px;
          margin-right: 20px;
          margin-left: 30px;
        }
      }
    }
  }
}
</style>
