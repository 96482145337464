<template>
  <div name="app">
    <router-view></router-view>
  </div>
</template>

<style lang="less">
@primary-color: #fe9d36; // 全局主色
</style>
<style lang="scss">
// @primary-color: #fe9d36; // 全局主色

* {
  font-family: Arial, MicrosoftYaHei !important;
}
.v-main {
  /* background-image: url("~@/assets/img/bgMain.png"); */
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}
::v-deep .ant-btn:hover,
.ant-btn:focus {
  border-color: none;
}
</style>

<script>
export default {
  name: "App",

  components: {},

  data: () => ({}),

  created() {},

  watch: {},

  methods: {},

  mounted: () => {},
};
</script>
