import { render, staticRenderFns } from "./descContentOdd.vue?vue&type=template&id=417e024c&scoped=true"
import script from "./descContentOdd.vue?vue&type=script&lang=js"
export * from "./descContentOdd.vue?vue&type=script&lang=js"
import style0 from "./descContentOdd.vue?vue&type=style&index=0&id=417e024c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "417e024c",
  null
  
)

export default component.exports