var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"class-hour-management flex items-center flex-col",staticStyle:{"display":"flex","align-items":"center","flex-direction":"column","padding":"80px 0 0 0 !important"}},[_c('div',{staticClass:"title wow animate__animated animate__fadeInUp",attrs:{"data-wow-duration":"2s"}},[_c('span',{staticStyle:{"color":"#fe880a"}},[_vm._v(" "+_vm._s(_vm.$t("field.lele"))+" ")]),_vm._v(" "+_vm._s(_vm.$t("field.platform"))+" ")]),_c('div',{staticClass:"desc wow animate__animated animate__fadeInUp",attrs:{"data-wow-duration":"4s"}},[_vm._v(" "+_vm._s(_vm.content.desc)+" ")]),_c('div',{staticClass:"desc wow animate__animated animate__fadeInUp",attrs:{"data-wow-duration":"4s"}},[_vm._v(" "+_vm._s(_vm.content.content)+" ")]),_c('div',{staticClass:"btn wow animate__animated animate__fadeInUp",attrs:{"data-wow-duration":"6s"}},[_c('v-btn',{staticClass:"mt-10",style:({
          'background-color': _vm.content.bottomColor,
          'border-color': _vm.content.bottomColor,
        }),attrs:{"rounded":"","outlined":"","large":"","dark":""},on:{"click":function($event){return _vm.goto('https://66.training/admin/login')}}},[_c('span',{staticStyle:{"font-size":"16px"}},[_vm._v(" "+_vm._s(_vm.$t("field.login"))+" ")])])],1),_c('div',{staticClass:"btn wow animate__animated animate__fadeInUp",attrs:{"data-wow-duration":"6s"}},[_c('v-btn',{staticClass:"mt-5",style:({
          'background-color': _vm.content.bottomColor,
          'border-color': _vm.content.bottomColor,
        }),attrs:{"rounded":"","outlined":"","large":"","dark":""},on:{"click":function($event){return _vm.jumbLeave()}}},[_c('span',{staticClass:"mr-2",staticStyle:{"font-size":"16px"}},[_vm._v(" "+_vm._s(_vm.content.btnText)+" ")]),_c('v-img',{attrs:{"src":_vm.content.btnDirection}})],1)],1),_c('div',{staticStyle:{"width":"300px","margin-top":"10px"}},[(_vm.toVideo == false)?_c('img',{staticStyle:{"width":"100%","height":"100%","cursor":"pointer"},attrs:{"src":_vm.content.newImg},on:{"click":() => {
            _vm.changeVideoPlayShow(false);
            _vm.toVideo = true;
          }}}):_vm._e(),(_vm.toVideo == true)?_c('div',{staticStyle:{"width":"300px"}},[_c('video',{ref:"videoExhibitRef",staticClass:"video-content",attrs:{"src":"https://66.training/training_read_minio/other/171c1c8c-84d3-450a-87ff-a03ed8e91585.mp4","controls":""},on:{"play":function($event){return _vm.changeVideoPlayShow(false)},"pause":function($event){return _vm.changeVideoPlayShow(true)}}})]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }