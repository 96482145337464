var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-illustrate-item",style:({
      '--flex-direction': _vm.flexDirection,
      'background-color': _vm.coverColor,
    })},[_c('div',{staticClass:"bg-img flex-1 wow animate__animated",class:_vm.animationEntry != 'animate__fadeInUp' ? _vm.animationEntry : ''},[_c('img',{staticClass:"img",attrs:{"src":_vm.image}})]),_c('div',{staticClass:"content-desc flex-1"},[_c('div',{staticClass:"content-desc-body"},[_c('div',{staticClass:"wow animate__animated com-title",class:_vm.animationEntry},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_vm._l((_vm.descs),function(desc,i){return _c('p',{staticClass:"desc wow animate__animated",class:_vm.animationEntry,attrs:{"data-wow-duration":"2s"}},[_vm._v(" "+_vm._s(desc)+" ")])}),_vm._l((_vm.features),function(item,i){return _c('div',{key:i,staticClass:"feature"},[_c('div',[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-start mb-4 wow animate__animated",class:_vm.animationEntry,style:({
                  'margin-bottom': item.title ? '10px' : '0',
                  'align-items': _vm.alignValue,
                }),attrs:{"cols":"24","data-wow-duration":2 + i++ + 's'}},[_c('v-img',{staticClass:"mr-4",attrs:{"src":item.icon,"max-width":"60px"}}),_c('span',{staticClass:"text-justify"},[(item.title)?_c('div',{staticClass:"iconDesc",staticStyle:{"margin-bottom":"10px","color":"#2d3f50"}},[_vm._v(" "+_vm._s(item.title)+" ")]):_vm._e(),_c('div',{staticClass:"iconContent"},[_vm._v(" "+_vm._s(item.content)+" ")])])],1)],1)],1)])})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }