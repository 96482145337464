<template>
  <v-dialog :max-width="maxWidth" persistent v-model="openDialog">
    <v-card :min-height="maxHeight" :width="maxWidth" rounded="xl">
      <v-card-text class="px-6 py-6">
        <div class="closeBtn" @click="close()">
          <v-img :src="require('@/assets/img/close.svg')" max-width="15" />
        </div>
        <div class="success-img">
          <v-img :src="require('@/assets/img/success.png')" max-width="100"></v-img>
        </div>
        <div class="title">
          {{ $t("field.honorific", { name: theName }) }}
        </div>

        <div class="btn">
          <v-btn rounded outlined large dark width="160" height="50" style="background-color: #5fb572; font-size: 21px" class="mt-10" @click="close()">
            <span class="mr-2" style="font-size: 16px"> {{ $t("field.confirm") }}({{ seconds + "s" }}) </span>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    openDialog: {
      type: Boolean,
      default: false,
    },
    maxHeight: {
      type: String,
      default: "200",
    },
    maxWidth: {
      type: String,
      default: "500",
    },
    theName: {
      type: String,
    },
  },
  data() {
    return {
      seconds: 5,
    };
  },
  methods: {
    close() {
      this.$emit("close");
      location.reload();
    },
    tradeSuccess() {
      this.seconds--;
      console.log(this.seconds);
      if (this.seconds > 0) {
        setTimeout(this.tradeSuccess, 1000);
      } else {
        this.close();
      }
    },
  },
  computed: {},
  created() {
    setTimeout(this.tradeSuccess, 1000);
  },
};
</script>

<style lang="scss" scoped>
.closeBtn {
  display: flex;
  justify-content: end;
  cursor: pointer;
}

.btn {
  display: flex;
  // align-items: center;
  justify-content: center;
}
@media (max-width: 640px) {
  .title {
    margin-top: 15px;
    text-align: center;
    color: #333;
    font-weight: bold;
    font-size: 20px !important;
  }
  .success-img {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media (min-width: 640px) {
  .title {
    margin-top: 15px;
    text-align: center;
    color: #333;
    // font-weight: bold;
    font-size: 20px !important;
  }
  .success-img {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
