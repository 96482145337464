import { LOCALE } from "@/settings/localeSetting";
import i18n from "@/locales/index";
export const useLocaleStore = {
  namespaced: true,
  state: () => ({
    locale: LOCALE.ZH_TW
    // localInfo: {
    //   // 当前语言
    //   locale: "",
    //   // 默认语言
    //   //   fallback: locale,
    //   // 允许的语言
    //   availableLocales: [LOCALE.ZH_CN, LOCALE.EN_US, LOCALE.ZH_TW]
    // }
  }),
  //   计算属性
  getters: {
    getLocale() {
      console.log("计算属性getLocale");
    }
  },
  actions: {
    refreshPage() {
      // 执行页面刷新的逻辑
      console.log("执行页面刷新 ");
    }
  },
  mutations: {
    getMutations() {
      console.log("getLoc", 222);
    },
    changeLocale(state, event) {
      state.locale = event.event || event || "en";
      i18n.locale = state.locale;
      // location.reload();
    }
  }
};
