<template>
  <v-dialog :max-width="maxWidth" persistent v-model="openDownloadDialog">
    <v-card :min-height="maxHeight" :width="maxWidth" rounded="xl">
      <v-card-text class="px-6 py-6">
        <div class="closeBtn" @click="close()">
          <v-img :src="require('@/assets/img/close.svg')" max-width="15" />
        </div>
        <div class="title">{{ $t("field.downloadAPP") }}</div>
        <div class="cards">
          <div class="card" v-for="(item, index) in downloadList" :key="index">
            <div class="top" :style="{ background: item.topCoverBg }">
              <v-img :src="item.svg" max-width="80" />
              <div class="name">{{ item.title }}</div>
            </div>
            <div class="bottom">
              <img :src="item.qrCodeImg" width="160" height="160" />
              <div class="desc">{{ item.desc }}</div>
            </div>
          </div>
        </div>
        <div class="btn">
          <v-btn
            rounded
            outlined
            large
            dark
            width="180"
            height="50"
            style="background-color: #ff870d; font-size: 21px"
            class="mt-10"
            @click="close()"
          >
            <span class="mr-2" style="font-size: 16px"> {{ $t("field.confirm") }} </span>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    openDownloadDialog: {
      type: Boolean,
      default: false,
    },
    maxHeight: {
      type: String,
      default: "600",
    },
    maxWidth: {
      type: String,
      default: "800",
    },
  },
  data() {
    return {};
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
  computed: {
    downloadList() {
      let _this = this;
      let downloadList = [
        {
          svg: require("@/assets/img/user-app.svg"),
          topCoverBg: "linear-gradient(138deg, #fbdc79 0%, #ff5965 100%)",
          title: _this.$t("field.downloadTheClientAPP"),
          qrCodeImg: require("@/assets/img/user-two.png"),
          desc: _this.$t("field.scanTheCodeToDownloadTheApplication"),
        },
        {
          svg: require("@/assets/img/teacher-app.svg"),
          topCoverBg: "linear-gradient( 171deg, #92FFB4 0%, #21BEC7 100%)",
          title: _this.$t("field.downloadTheTeacherAPP"),
          qrCodeImg: require("@/assets/img/teacher-two.png"),
          desc: _this.$t("field.scanTheCodeToDownloadTheApplication"),
        },
      ];
      return downloadList;
    },
  },
};
</script>

<style lang="scss" scoped>
.closeBtn {
  display: flex;
  justify-content: end;
  cursor: pointer;
}

.btn {
  display: flex;
  // align-items: center;
  justify-content: center;
}
@media (max-width: 640px) {
  .title {
    margin-top: 15px;
    text-align: center;
    color: #333;
    font-weight: bold;
    font-size: 30px !important;
  }
  .cards {
    // padding: 0 10px;
    display: flex;
    align-items: center;
    // justify-content: space-between;
    flex-direction: column;
    .card {
      width: 300px;
      height: 300px;
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .top {
        height: 100px;
        border-radius: 14px 14px 0px 0px;
        padding: 13px 23px;
        display: flex;
        align-items: center;
        .name {
          color: #fff;
          font-weight: bold;
          font-size: 21px;
          margin-left: 10px;
        }
        .v-img {
          width: 60px;
          height: 60px;
        }
      }
      .bottom {
        border: 1px solid #e5e5e5;
        border-radius: 0px 0px 14px 14px;
        flex: 1;
        padding: 10px 0;
        display: flex;
        flex-direction: column;
        //   justify-content: center;
        align-items: center;
        .desc {
          font-size: 18px;
          color: #666;
          margin-top: 10px;
        }
      }
    }
  }
}
@media (min-width: 640px) {
  .title {
    margin-top: 15px;
    text-align: center;
    color: #333;
    font-weight: bold;
    font-size: 36px !important;
  }
  .cards {
    padding: 0 35px;
    display: flex;
    align-items: center;
    margin-top: 30px;
    justify-content: space-between;
    .card {
      width: 330px;
      height: 350px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .top {
        height: 100px;
        border-radius: 14px 14px 0px 0px;
        padding: 13px 23px;
        display: flex;
        align-items: center;
        .name {
          color: #fff;
          font-weight: bold;
          font-size: 21px;
          margin-left: 10px;
        }
      }
      .bottom {
        border: 1px solid #e5e5e5;
        border-radius: 0px 0px 14px 14px;
        flex: 1;
        padding: 15px 0;
        display: flex;
        flex-direction: column;
        //   justify-content: center;
        align-items: center;
        .desc {
          font-size: 20px;
          color: #666;
          margin-top: 10px;
        }
      }
    }
  }
}
</style>
