<template>
  <div>
    <v-parallax class="big-area" dark style="padding: 0 !important" :src="require('@/assets/img/customization.png')" height="690">
      <div>
        <a-row align="middle" type="flex" justify="center" style="height: 500px">
          <a-col :span="12" style="display: flex; align-items: center; justify-content: center">
            <span class="customization-title">
              {{ $t("field.customization") }}
            </span>
          </a-col>
          <a-col :span="12" style="padding-left: 40px"> </a-col>
        </a-row>
        <a-row align="middle" type="flex" justify="center" style="height: 190px">
          <a-col :span="24" class="img-list-box">
            <div v-for="(item, index) in imgList">
              <img :src="item.imgUrl" alt="" class="img-c" />
            </div>
          </a-col>
        </a-row>
      </div>
    </v-parallax>
    <v-parallax dark class="small-area" style="padding: 0 !important" :src="require('@/assets/img/app-phone-bg.png')" height="690">
      <!-- 手机端 -->
      <div class="py-5">
        <a-row type="flex" justify="center" style="height: 440px">
          <a-col :span="24" style="display: flex; justify-content: center; height: 110px">
            <span class="customization-title">
              {{ $t("field.customization") }}
            </span>
          </a-col>
          <a-col :span="24" style="display: flex; justify-content: center; height: 330px">
            <img src="@/assets/img/app-img.png" alt="" class="img-phone" />
          </a-col>
        </a-row>
        <a-row align="middle" type="flex" justify="center" style="height: 250px">
          <a-col :span="24" class="img-list-box">
            <div class="swiper-container3" style="margin-top: 20px">
              <div class="swiper-wrapper">
                <div v-for="(item, index) in imgList1" class="swiper-slide">
                  <img :src="item.imgUrl" alt="" class="img-c" />
                </div>
              </div>
            </div>
            <div class="swiper-container2" style="margin-top: 20px">
              <div class="swiper-wrapper">
                <div v-for="(item, index) in imgList2" class="swiper-slide">
                  <img :src="item.imgUrl" alt="" class="img-c" />
                </div>
              </div>
            </div>
          </a-col>
        </a-row>
      </div>
    </v-parallax>
    <!-- <div class="swiper-container">
          <div class="swiper-wrapper">
            <div v-for="item in imgList" :key="item.id" class="swiper-slide" @mousedown="mousedownImg()">
              <img :src="item.imgUrl" class="on-hover-img" />
            </div>
          </div>
        </div> -->
  </div>
</template>

<script>
import Swiper from "swiper"; // 注意引入的是Swiper
import "swiper/css/swiper.min.css"; // 注意这里的引入
export default {
  name: "App",
  components: {},
  data: () => ({
    fab: null,
    color: "",
    flat: null,
    imgList: [
      {
        id: 1,
        imgUrl: require("@/assets/img/supplier-1.png"),
      },
      {
        id: 2,
        imgUrl: require("@/assets/img/supplier-2.png"),
      },
      {
        id: 3,
        imgUrl: require("@/assets/img/supplier-3.png"),
      },
      {
        id: 4,
        imgUrl: require("@/assets/img/supplier-4.png"),
      },
      {
        id: 5,
        imgUrl: require("@/assets/img/supplier-5.png"),
      },
      {
        id: 6,
        imgUrl: require("@/assets/img/supplier-6.png"),
      },
      {
        id: 7,
        imgUrl: require("@/assets/img/supplier-7.png"),
      },

      {
        id: 8,
        imgUrl: require("@/assets/img/supplier-8.png"),
      },
      {
        id: 9,
        imgUrl: require("@/assets/img/supplier-9.png"),
      },
      {
        id: 10,
        imgUrl: require("@/assets/img/supplier-10.png"),
      },
      {
        id: 11,
        imgUrl: require("@/assets/img/supplier-11.png"),
      },
      {
        id: 12,
        imgUrl: require("@/assets/img/supplier-12.png"),
      },
      {
        id: 13,
        imgUrl: require("@/assets/img/supplier-13.png"),
      },
    ],
    imgList1: [
      {
        id: 1,
        imgUrl: require("@/assets/img/supplier-1.png"),
      },
      {
        id: 2,
        imgUrl: require("@/assets/img/supplier-2.png"),
      },
      {
        id: 3,
        imgUrl: require("@/assets/img/supplier-3.png"),
      },
      {
        id: 4,
        imgUrl: require("@/assets/img/supplier-4.png"),
      },
      {
        id: 5,
        imgUrl: require("@/assets/img/supplier-5.png"),
      },
      {
        id: 6,
        imgUrl: require("@/assets/img/supplier-6.png"),
      },
      {
        id: 7,
        imgUrl: require("@/assets/img/supplier-7.png"),
      },
    ],
    imgList2: [
      {
        id: 8,
        imgUrl: require("@/assets/img/supplier-8.png"),
      },
      {
        id: 9,
        imgUrl: require("@/assets/img/supplier-9.png"),
      },
      {
        id: 10,
        imgUrl: require("@/assets/img/supplier-10.png"),
      },
      {
        id: 11,
        imgUrl: require("@/assets/img/supplier-11.png"),
      },
      {
        id: 12,
        imgUrl: require("@/assets/img/supplier-12.png"),
      },
      {
        id: 13,
        imgUrl: require("@/assets/img/supplier-13.png"),
      },
    ],
  }),

  computed: {},
  created() {},

  watch: {},

  methods: {
    mousedownImg() {
      console.log(11111, 200);
    },

    getSwiper() {
      this.swiper = new Swiper(".swiper-container3", {
        loop: false, // 无缝
        allowTouchMove: false,
        initialSlide: 1,
        // loopedSlides: 8,
        // loopAdditionalSlides: 2,
        autoplay: {
          delay: 0,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        autoplayDisableOnInteraction: true,

        // 窗口变化,重新init,针对F11全屏和放大缩小,必须加
        observer: true,
        speed: 10000,
        observeParents: true,
        freeMode: true,
        paginationClickable: true,
        slidesPerView: 4, // 一组三个
        spaceBetween: 30, // 间隔
        // 如果需要前进后退按钮
        // navigation: {
        //   nextEl: ".swiper-button-next",
        //   prevEl: ".swiper-button-prev",
        // },
        // 窗口变化,重新init,针对F11全屏和放大缩小,必须加
        observer: true,
        observeParents: true,
        // 如果需要分页器
        pagination: {
          el: ".swiper-pagination",
          clickable: true, // 分页器可以点击
        },
      });
      this.swiper = new Swiper(".swiper-container2", {
        loop: false, // 无缝
        allowTouchMove: false,
        initialSlide: 5,
        // loopedSlides: 8,
        // loopAdditionalSlides: 2,
        autoplay: {
          delay: 0,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        autoplayDisableOnInteraction: true,

        // 窗口变化,重新init,针对F11全屏和放大缩小,必须加
        observer: true,
        speed: 10000,
        observeParents: true,
        freeMode: true,
        paginationClickable: true,
        slidesPerView: 4, // 一组三个
        spaceBetween: 30, // 间隔
        // 如果需要前进后退按钮
        // navigation: {
        //   nextEl: ".swiper-button-next",
        //   prevEl: ".swiper-button-prev",
        // },
        // 窗口变化,重新init,针对F11全屏和放大缩小,必须加
        observer: true,
        observeParents: true,
        // 如果需要分页器
        pagination: {
          el: ".swiper-pagination",
          clickable: true, // 分页器可以点击
        },
      });
    },
  },
  mounted() {
    this.getSwiper();
  },
};
</script>

<style scoped>
.scroll-container {
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  cursor: grab;
  user-select: none; /* 防止拖动时选中文本 */
}

.scroll-content {
  display: inline-block;
}

/* 拖动时改变光标 */
.scroll-container:active {
  cursor: grabbing;
}
.swiper-container .swiper-wrapper {
  transition-timing-function: linear !important;
  transition-duration: 10000ms !important;
}
.swiper-container .swiper-wrapper-2 {
  transition-timing-function: linear !important;
  transition-duration: 12000ms !important;
}
.on-hover-img {
  transition: transform 0.3s ease;
  z-index: 99;
}
:deep(.v-parallax__content) {
  padding: 0 0 !important;
  height: 690px !important;
}

:deep(.v-parallax__image-container .v-parallax__image) {
  transform: translate(-50%, 0px) !important;
}

@media (min-width: 640px) {
  .small-area {
    display: none;
  }
  .big-area {
    width: auto;
    height: 690px;
    color: black;
  }
  .customization-title {
    font-size: 50px;
    color: #2d3f50;
    text-align: right;
    font-style: normal;
  }
  .img-list-box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background: rgba(255, 255, 255, 0.5);
    height: 100%;
  }
  .img-c {
    width: 85px;
    height: 85px;
    margin: 0 10px;
    transition-property: all;
    transition-duration: 0.5s;
    transition-timing-function: ease;
    transition-delay: 0s;
    &:hover {
      transform: scale(1.2);
    }
  }
}
/* 手机版 */
@media (max-width: 639.99px) {
  .big-area {
    display: none;
  }
  .small-area {
    display: block;
  }
  .customization-title {
    margin-top: 10px;
    font-size: 40px;
    color: #2d3f50;
    font-style: normal;
  }
  .img-list-box {
    background: rgba(255, 255, 255, 0.5);
    height: 100%;
  }
  .img-c {
    width: 85px;
    height: 85px;
    margin: 0 10px;
    transition-property: all;
    transition-duration: 0.5s;
    transition-timing-function: ease;
    transition-delay: 0s;
    &:hover {
      transform: scale(1.2);
    }
  }
}
.img-phone {
  width: 100%;
  height: 100%;
}
</style>
