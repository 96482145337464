<template>
  <v-app>
    <!-- 可修改頁面 -->
    <navigation :flat="true" demoName="0" />
    <v-main
      :key="appId"
      class="pt-0"
      :style="{
        'background-image': `url(${require(`@/assets/img/${config.backgroundImg}`)})`,
      }"
    >
      <div class="main-content">
        <section class="banner">
          <div class="part1">
            <div
              class="title wow animate__animated animate__fadeInUp"
              data-wow-duration="0s"
            >
              {{ $t(config.banner.title1) }}
            </div>
            <div
              class="subtitle wow animate__animated animate__fadeInUp"
              data-wow-duration="1.5s"
            >
              {{ $t(config.banner.title2) }}
            </div>
            <div
              class="tip wow animate__animated animate__fadeInUp"
              data-wow-duration="3s"
            >
              {{ $t(config.banner.tip) }}
            </div>
            <v-btn
              class="appointment-btn wow animate__animated animate__fadeInUp"
              data-wow-duration="4.5s"
              rounded
              large
              dark
              color="#FE880A"
              @click="goAppointment"
            >
              <span style="font-size: 16px; margin-right: 16px">
                {{ $t("content.appointmentDemonstration") }}
              </span>
              <v-img :src="require('@/assets/img/right-arrow.svg')" />
            </v-btn>
          </div>
          <div class="part2">
            <v-img
              :aspect-ratio="1"
              :src="require(`@/assets/img/${config.banner.img}`)"
              :contain="true"
            />
          </div>
        </section>
        <section class="desc">
          <div
            class="title wow animate__animated animate__fadeInUp"
            data-wow-duration="5s"
          >
            {{ $t(config.desc.title) }}
          </div>
          <div class="desc-list">
            <div
              v-for="(item, index) in config.desc.boxes"
              :key="index"
              class="desc-item wow animate__animated animate__fadeInUp"
              :style="{ background: item.background }"
              :data-wow-duration="`${index + 3}s`"
            >
              <div style="justify-self: flex-start">
                <v-img
                  :src="require(`@/assets/img/${item.icon}`)"
                  :height="65"
                  :width="65"
                  :contain="true"
                />
              </div>
              <div class="desc-title">{{ $t(item.title) }}</div>
              <div class="desc-text">{{ $t(item.desc) }}</div>
              <div v-if="item.tip" class="desc-tip">{{ $t(item.tip) }}</div>
            </div>
          </div>
        </section>
        <section v-if="config.feature" class="feature">
          <div class="title wow animate__animated animate__fadeInUp">
            {{ $t(config.feature.title) }}
          </div>
          <div class="feature-list">
            <div
              v-for="(item, index) in config.feature.boxes"
              :key="index"
              class="feature-item wow animate__animated animate__fadeInUp"
              :data-wow-duration="`${index + 3}s`"
            >
              <div style="justify-self: flex-start">
                <v-img
                  :src="require(`@/assets/img/${item.icon}`)"
                  :width="82"
                  :height="82"
                  :contain="true"
                />
              </div>
              <div class="desc-text">{{ $t(item.desc) }}</div>
              <div v-if="item.tip">{{ $t(item.tip) }}</div>
            </div>
          </div>
        </section>
        <section class="system">
          <div>
            <v-img
              :aspect-ratio="1"
              :src="require(`@/assets/img/${config.system.leftImg}`)"
              :contain="true"
            />
          </div>
          <div>
            <div class="title wow animate__animated animate__fadeInUp">
              {{ $t(config.system.title) }}
            </div>
            <div
              v-for="(item, index) in config.system.boxes"
              :key="index"
              class="system-list-item wow animate__animated animate__fadeInUp"
              :data-wow-duration="`${index + 3}s`"
            >
              <div class="icon-wrapper" :style="{ background: item.iconColor }">
                <v-img
                  :src="require(`@/assets/img/${item.icon}`)"
                  :width="40"
                  :height="40"
                  :contain="true"
                />
              </div>
              <div class="desc-text">{{ $t(item.desc) }}</div>
            </div>
          </div>
        </section>
      </div>
    </v-main>
    <v-scale-transition>
      <v-btn
        fab
        v-show="fab"
        v-scroll="onScroll"
        dark
        fixed
        bottom
        right
        color="secondary"
        @click="toTop"
      >
        <v-icon>mdi-arrow-up</v-icon>
      </v-btn>
    </v-scale-transition>
    <foote />
  </v-app>
</template>

<style lang="scss" scoped>
/* * {
    font-family: MicrosoftYaHei, MicrosoftYaHei !important;
  } */
.v-main {
  /* background-image: url("~@/assets/img/bgMain.png"); */
  background-attachment: scroll;
  background-position: center;
  background-size: cover;
}
.main-content {
  width: 100%;
}

.banner {
  display: flex;
  align-items: center;
  padding: 138px 0px 58px;
  box-sizing: border-box;
  width: 100%;
  .part1 {
    width: 55%;
  }
  .part2 {
    width: 45%;
  }
  .title {
    font-size: 60px !important;
    color: #333333;
  }
  .subtitle {
    margin-top: 35px;
    font-size: 50px;
    color: #333333;
  }
  .appointment-btn {
    margin-top: 120px;
  }
  .tip {
    margin-top: 40px;
    font-size: 30px;
    color: #666666;
  }
}
.desc {
  padding: 58px 0px;
  .title {
    font-size: 42px !important;
    color: #333333;
    text-align: center;
  }
  .desc-list {
    margin-top: 50px;
    display: flex;
    // align-items: center;
    justify-content: center;
    .desc-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 400px;
      flex: 1;
      background: linear-gradient(316deg, #fedddc 0%, #fee8e7 100%);
      margin-left: 25px;
      padding: 65px 34px 34px;
      border-radius: 10px;
      &:first-child {
        margin-left: 0px;
      }
      .desc-title {
        font-weight: bold;
        font-size: 26px;
        color: #666666;
        margin-top: 40px;
      }
      .desc-text {
        font-size: 24px;
        color: #666666;
        text-align: center;
        margin-top: 30px;
      }
      .desc-tip {
        font-size: 18px;
        color: #666666;
        line-height: 36px;
        text-align: center;
      }
    }
  }
}

.feature {
  padding: 58px 0px;
  .title {
    font-size: 42px !important;
    line-height: 50px;
    color: #333333;
    text-align: center;
  }
  .feature-list {
    margin-top: 50px;
    display: flex;
    // align-items: center;
    justify-content: center;
    .feature-item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      max-width: 300px;
      flex: 1;
      background: #ffffff;
      box-shadow: 0px 7px 10px 0px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      margin-left: 25px;
      padding: 30px;
      &:first-child {
        margin-left: 0px;
      }
      .desc-text {
        margin-top: 20px;
        font-size: 22px;
        color: #666666;
        line-height: 45px;
      }
    }
  }
}

.system {
  width: 100%;
  padding: 58px 0px 120px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0 80px;
  align-items: center;
  .title {
    font-size: 42px !important;
    color: #333333;
    text-align: left;
    margin-bottom: 50px;
    line-height: 60px;
  }
  .system-list-item {
    display: flex;
    align-items: center;
    padding: 18px 25px;
    background-color: #fff;
    box-shadow: 0px 7px 10px 0px rgba(0, 0, 0, 0.2);
    margin-top: 30px;
    border-radius: 10px;
    white-space: wrap;
    .icon-wrapper {
      border-radius: 82px;
      height: 82px;
      width: 82px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
    }
    .desc-text {
      font-size: 22px;
      color: #666666;
      line-height: 36px;
      margin-left: 37px;
    }
  }
}

@media (min-width: 1200px) {
  .main-content {
    width: 1200px;
    margin: 0 auto;
  }
}

@media (max-width: 1200px) {
  .main-content {
    width: 100%;
    padding: 0 30px;
  }
}

@media (max-width: 640px) {
  .main-content {
    width: 100%;
    padding: 0 16px;
  }
  .banner {
    flex-direction: column;
    padding: 138px 0px 58px;
    .part1 {
      width: 100%;
    }
    .part2 {
      width: 100%;
      margin-top: 30px;
    }
    .title {
      font-size: 40px !important;
      line-height: 46px;
    }
    .subtitle {
      font-size: 30px;
      margin-top: 20px;
    }
    .tip {
      font-size: 20px;
      margin-top: 30px;
    }
    .appointment-btn {
      margin-top: 40px;
    }
  }
  .desc {
    .title {
      font-size: 30px !important;
    }
    .desc-list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
      .desc-item {
        margin-left: 0px;
        .desc-title {
          font-size: 22px;
        }
        .desc-text {
          font-size: 20px;
          line-height: 32px;
          margin-top: 10px;
        }
        .desc-tip {
          font-size: 16px;
          line-height: 28px;
        }
        :deep(.v-image) {
          size: 42px !important;
          height: 42px !important;
        }
      }
    }
  }
  .feature {
    .title {
      font-size: 30px !important;
    }
    .feature-list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
      .feature-item {
        margin-left: 0;
        .desc-text {
          margin-top: 16px;
          font-size: 18px;
          line-height: 30px;
        }
        :deep(.v-image) {
          width: 60px !important;
          height: 60px !important;
        }
      }
    }
  }
  .system {
    grid-template-columns: 100%;
    .title {
      font-size: 30px !important;
      margin-bottom: 30px;
    }
    .system-list-item {
      box-sizing: border-box;
      margin-top: 16px;
      .icon-wrapper {
        border-radius: 62px;
        height: 62px;
        width: 62px;
        :deep(.v-image) {
          width: 30px !important;
          height: 30px !important;
        }
      }
      .desc-text {
        font-size: 18px;
        margin-left: 24px;
        line-height: 28px;
      }
    }
  }
}
</style>

<script>
import navigation from "@/components/common/NavigationBar";
import foote from "@/components/common/Footer01";
import industryApplicationConfig from "@/assets/config/industryApplicationConfig.json";

export default {
  name: "App",

  components: {
    navigation,
    foote,
  },
  props: {
    appId: {
      type: [String, Number],
    },
  },
  data: () => ({
    fab: null,
    color: "",
    flat: null,
    curDuration: 5,
    config: () => ({}),
    wow: null,
  }),
  computed: {
    coverData() {
      let _this = this;
      let coverData = {
        title: _this.$t("field.lelePlatform"),
        subTitle: _this.$t("content.expandMoreInstitution"),
        content: _this.$t("content.clearerIncomeCategories"),
        btnText: _this.$t("field.mainBusiness"),
        direction: require("@/assets/img/bottom-arrow.svg"),
        coverImg: require("@/assets/img/bgHero2.png"),
        bottomCover: require("@/assets/img/borderWaves.svg"),
      };
      return coverData;
    },
  },

  created() {
    const top = window.pageYOffset || 0;
    if (top <= 60) {
      this.color = "transparent";
      this.flat = true;
    }
  },

  watch: {
    fab(value) {
      if (value) {
        this.color = "secondary";
        this.flat = false;
      } else {
        this.color = "transparent";
        this.flat = true;
      }
    },
    appId: {
      handler(value) {
        const config = industryApplicationConfig[value];
        this.config = config;
        new this.$wow.WOW({ offset: 20 }).init();
      },
      immediate: true,
    },
  },

  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 60;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    goAppointment() {
      this.$router.push("/leave_message");
    },
  },
};
</script>
