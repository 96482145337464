<template>
  <div
    class="class-hour-management flex items-center flex-col"
    style="
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 80px 0 0 0 !important;
      margin-top: 20px;
    "
    :style="{ height: content.features.length < 4 ? '1100px' : '1000px' }"
  >
    <div class="title wow animate__animated animate__fadeInUp" data-wow-duration="2s">
      {{ content.title }}
    </div>
    <div class="desc wow animate__animated animate__fadeInUp" data-wow-duration="4s">
      {{ content.desc }}
    </div>
    <div class="btn wow animate__animated animate__fadeInUp" data-wow-duration="6s">
      <v-btn
        rounded
        outlined
        large
        dark
        @click="jumbLeave(1)"
        style="background: linear-gradient(90deg, #fe8e5c 0%, #f75c4a 100%)"
        class="mt-10 !px-3"
      >
        <span style="font-size: 16px"> {{ content.loginBtn }} </span>
        <!-- <v-img :src="content.btnDirection" max-width="24" max-height="18" /> -->
      </v-btn>
      <v-btn
        rounded
        outlined
        large
        dark
        @click="goHand('https://1714964447106-h5.eshops66.com/?storeId=1714964447106')"
        style="background: linear-gradient(90deg, #fe8e5c 0%, #f75c4a 100%)"
        class="mt-10 ml-3 !px-3"
      >
        <span style="font-size: 16px"> {{ content.twoBtn }} </span>

        <!-- <v-img :src="content.btnDirection" max-width="24" max-height="18" /> -->
      </v-btn>
    </div>
    <img :src="content.coverImg" class="w-0" />

    <div class="features">
      <div
        class="feature wow animate__animated animate__fadeInUp"
        v-for="(item, index) in content.features"
        :key="index"
        :data-wow-duration="index++ + 's'"
      >
        <p>
          <!-- <svg-icon icon-class="co2lor-course-type" /> -->
          <v-img :src="item.icon" />
        </p>
        <p
          class="card-title"
          :class="index === content.features.length ? 'card-title-width' : 'card-title'"
        >
          {{ item.title }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    content: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    gotoNew(url) {
      window.open(url, "_blank");
    },
    jumbLeave(url) {
      if (url === 1) {
        // this.gotoNew("https://66.training/admin/register")
        this.gotoNew("https://seller.eshops66.com/");
      } else {
        this.gotoNew("https://66.training/contact_us");
      }
    },
    goHand(url) {
      window.open(url, "_blank");
    },
  },
  mounted() {
    new this.$wow.WOW().init();
  },
};
</script>

<style lang="scss" scoped>
// * {
//   font-family: MicrosoftYaHei !important;
// }

@media (max-width: 640px) {
  .class-hour-management {
    min-height: 750px;
    // background-color: red;
    background-image: url("../../assets/img/store-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    height: auto !important;
    .title {
      // color: #2d3f50 ;
      font-size: 30px !important;
      line-height: 30px !important;
      background: linear-gradient(
        to right,
        #f75c4a,
        #fe8e5c
      ); /*设置渐变的方向从左到右 颜色从ff0000到ffff00*/
      -webkit-background-clip: text; /*将设置的背景颜色限制在文字中*/
      -webkit-text-fill-color: transparent; /*给文字设置成透明*/
      font-weight: bold;
      // font-family: MicrosoftYaHei, MicrosoftYaHei !important;
    }

    .desc {
      margin-top: 10px;
      padding: 0 50px;
      color: #666;
      font-family: MicrosoftYaHei;
      font-size: 20px;
      text-align: center;
    }
    .btn {
      .v-btn {
        min-width: 130px;
      }
    }
    .card-title {
      font-size: 14px;
      width: 100px;
      text-align: center;
      margin-top: 10px;
    }
    .card-title-width {
      margin-top: 10px;
      font-size: 14px;
      width: 100px;
    }

    img {
      // width: 901px;
      // height: 718px;
      width: 100%;
      // height: auto;
      object-fit: contain;
      margin-top: 20px;
    }

    .features {
      display: grid;
      width: 100% !important;
      // 横向
      // grid-gap: 30px 20px;
      // grid-template-columns: 1fr;
      // grid-template-rows: repeat(1fr, 4);
      // 竖着
      display: flex;
      justify-content: space-evenly;
      align-items: stretch;

      .feature {
        @apply flex items-center;

        p {
          margin-bottom: 0;
        }
        // padding-right: 10px;
        width: 118px;
        // height: 73px;
        min-height: 130px;
        border-radius: 10px;
        background: #fff;
        box-shadow: 0 5px 8px 0 rgb(0 0 0 / 20%);
        color: #666;
        font-weight: bold;
        font-family: MicrosoftYaHei;
        display: flex;
        flex-direction: column;
        align-items: center;
        // justify-content: center;
        padding: 15px;

        .v-image {
          width: 52px;
          height: 52px;
          margin-right: 20px;
          margin-left: 15px;
        }
      }
    }
  }
}

@media (min-width: 640px) {
  .class-hour-management {
    position: relative;
    // height: 1100px;
    padding-top: 60px !important;
    background-image: url("../../assets/img/store-bg.png");
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    height: auto !important;
    .title {
      // color: #2d3f50 ;
      margin-top: 20px;
      font-size: 60px !important;
      line-height: 79px !important;
      background: linear-gradient(
        to right,
        #f75c4a,
        #fe8e5c
      ); /*设置渐变的方向从左到右 颜色从ff0000到ffff00*/
      -webkit-background-clip: text; /*将设置的背景颜色限制在文字中*/
      -webkit-text-fill-color: transparent; /*给文字设置成透明*/
      font-weight: bold;
      // font-family: MicrosoftYaHei, MicrosoftYaHei !important;
    }

    .desc {
      margin-top: 20px;
      width: 800px;
      color: #666;
      font-family: MicrosoftYaHei;
      font-size: 26px;
      text-align: center;
    }
    .btn {
      .v-btn {
        min-width: 143px;
      }
    }

    .card-title {
      margin-top: 20px;
      font-size: 20px;
      width: 120px;
    }

    .card-title-width {
      margin-top: 20px;
      font-size: 20px;
      width: 140px;
    }

    img {
      // width: 901px;
      height: 650px;
      width: 100%;
      // height: auto;
      object-fit: contain;
      margin-top: 20px;
    }

    .features {
      display: flex;
      // justify-content: center;
      align-items: stretch;
      position: absolute;
      bottom: 60px;
      // grid-gap: 30px 20px;
      // grid-template-columns: 1fr 1fr;
      // grid-template-rows: 1fr 1fr;
      width: 800px;

      .feature {
        @apply flex items-center;
        p {
          margin-bottom: 0;
        }
        margin-right: 25px;
        width: 250px;
        min-height: 200px;
        border-radius: 10px;
        background: #fff;
        box-shadow: 0 5px 8px 0 rgb(0 0 0 / 20%);
        color: #666;
        font-family: MicrosoftYaHei;
        font-size: 20px;
        padding: 20px;
        font-weight: bold;
        display: flex;
        flex-direction: column;
        align-items: center;
        // justify-content: center;
        text-align: center;

        .v-image {
          width: 70px;
          height: 70px;
          margin-right: 40px;
          margin-left: 30px;
        }
      }
    }
  }
}
</style>
