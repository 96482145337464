<template>
  <div ref="applicableIndustries" class="applicable-industries flex items-center flex-col">
    <p class="title wow animate__animated animate__fadeInUp">{{ $t("field.applicableIndustries") }}</p>
    <p data-wow-duration="1s" class="tips wow animate__animated animate__fadeInUp">{{ $t("content.leleCanBeUsed") }}</p>
    <div class="industries">
      <div
        v-for="(item, index) of getIndustryList"
        :key="index"
        :data-wow-duration="item.duration + 's'"
        class="industry wow animate__animated animate__fadeInUp"
        :style="{
          'background-image': `url(${require(`@/assets/img/${item.backgroundImage}`)})`
        }"
      >
        <v-img :src="item.icon" contain height="50" />

        <p class="industry-name">{{ item.name }}</p>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    getIndustryList() {
      let _this = this;
      let industryList = [
        {
          name: _this.$t("field.offlineAndOnline"),
          backgroundImage: "profession-bg1.png",
          icon: require("@/assets/img/profession-icon1.svg"),
          duration: 2
        },
        {
          name: _this.$t("field.privateEducation"),
          backgroundImage: "profession-bg2.png",
          icon: require("@/assets/img/profession-icon2.svg"),
          duration: 4
        },
        {
          name: _this.$t("field.clubs"),
          backgroundImage: "profession-bg3.png",
          icon: require("@/assets/img/profession-icon3.svg"),
          duration: 6
        },
        {
          name: _this.$t("field.videoBlogger"),
          backgroundImage: "profession-bg4.png",
          icon: require("@/assets/img/profession-icon4.svg"),
          duration: 8
        },
        {
          name: _this.$t("field.summerCamp"),
          backgroundImage: "profession-bg5.png",
          icon: require("@/assets/img/profession-icon5.svg"),
          duration: 10
        },
        {
          name: _this.$t("field.schoolClubs"),
          backgroundImage: "profession-bg6.png",
          icon: require("@/assets/img/profession-icon6.svg"),
          duration: 12
        }
      ];
      return industryList;
    }
  },
  mounted() {
    new this.$wow.WOW().init();
  }
};
</script>

<style lang="scss" scoped>
// * {
//   font-family: MicrosoftYaHei !important;
// }
@media (min-width: 640px) {
  .applicable-industries {
    // height: 800px;
    padding-top: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 20px 0 200px 0;
    background-color: #f4f9f6;

    .title {
      font-size: 50px !important;
      color: #2d3f50;
      // font-family: MicrosoftYaHei !important;
    }

    .tips {
      width: 609px;
      margin-top: 30px;
      color: #333333;
      font-family: MicrosoftYaHei;
      font-size: 26px;
      text-align: center;
    }

    .industries {
      display: grid;
      grid-gap: 25px 33px;
      grid-template-columns: repeat(3, 415px);
      grid-template-rows: repeat(2, 260px);
      margin-top: 110px;

      .industry {
        // @apply flex items-center justify-center flex-col;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-radius: 20px;
        .v-responsive {
          //   flex: 0;
          flex-grow: 0;
        }
        &-name {
          color: #fff;
          font-family: MicrosoftYaHei;
          font-size: 28px;
        }
        .v-image {
          margin-bottom: 17px;
          color: white;
          width: 70px;
          height: 70px;
        }

        .svg-icon {
          margin-bottom: 17px;
          color: white;
          font-size: 70px;
        }
      }
    }
  }
}
// 小于640的时候
@media (max-width: 640px) {
  .applicable-industries {
    // height: 900px;
    padding: 50px 0 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #f4f9f6;

    .title {
      // font-family: MicrosoftYaHei !important;
      font-size: 30px !important;
      color: #2d3f50;
      text-align: center;
    }

    .tips {
      margin-top: 10px;
      color: #333333;
      // font-family: MicrosoftYaHei;
      font-size: 20px !important;
      text-align: center;
    }

    .industries {
      display: grid;
      grid-gap: 25px 33px;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, 130px);
      width: 92%;
      margin-top: 50px;

      .industry {
        // @apply flex items-center justify-center flex-col;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;

        border-radius: 20px;
        background-size: 100% 100%;
        .v-responsive {
          //   flex: 0;
          flex-grow: 0;
        }
        &-name {
          width: 100%;
          color: #fff;
          font-family: MicrosoftYaHei;
          font-size: 18px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .v-image {
          margin-bottom: 5px;
          color: white;
          width: 40px;
          height: 40px;

          //   height: 55px;
        }
        .svg-icon {
          margin-bottom: 17px;
          color: white;
          font-size: 38px;
        }
      }
    }
  }
}
</style>
