export function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // 检查是否ios设备
    if ((/iPad|iPhone|iPod/.test(userAgent)&& !window.MSStream)) {
        return 'iOS';
    }
    // 检查是否安卓设备
    else if (/android/i.test(userAgent)) {
        return 'Android';
    } else {
        return 'unknown';
    }
}

export const downloadList = [
    {
        text: "field.teacherApp",
        iosUrl: 'https://apps.apple.com/us/app/%E6%A8%82%E6%A8%82%E5%8A%A9%E6%89%8B/id1597207080',
        androidUrl:'https://hypertechelect.com/community/api/system/downloadUpdate?package=Luck.TrainingV2(teacher)&mode=full&system=android'
    },
    {
        text: "field.userApp",
        iosUrl: 'https://apps.apple.com/us/app/%E6%A8%82%E6%A8%82%E7%94%A8%E6%88%B6/id6450739216',
        androidUrl:'https://hypertechelect.com/community/api/system/downloadUpdate?package=66.training(user)&mode=full&system=android'
    }
  ];
 
