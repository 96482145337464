import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import { useLocaleStore } from "./modules/locale";
Vue.use(Vuex);
// 创建一个新的 store 实例
const store = new Vuex.Store({
  state() {
    return {
      count: 0,
      num: 1,
      name: {}
    };
  },
  // 计算属性
  getters: {
    getcount() {
      return state.count;
    }
  },
  // 方法
  mutations: {
    increment(state) {
      return state.count++;
    },
    setDetailQuery(state, query) {
      state.count = query || 0;
    },
    resetState() {
      // var that = this;
      // that.$i18n.locale =  state.useLocaleStore.localInfo.locale;
    },
    changeName(state, name) {
      state.name = name;
    }
  },
  modules: {
    useLocaleStore
  },
  plugins: [new VuexPersistence().plugin]
});

export default store;
