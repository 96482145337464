import { createContext, useContext } from "@/hooks/core/useContext";

const key = Symbol();
export function createAnnouncementContext(context) {
  return createContext(context, key, { readonly: false });
}

export function useAnnouncementContext() {
  return useContext(key);
}
