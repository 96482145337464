<template>
  <div
    id="App"
    class="desc-content-item"
    :style="{
      background: coverColor,
    }"
  >
    <div class="content">
      <div class="wow animate__animated animate__fadeInUp com-title">
        {{ title }}
      </div>
      <p
        v-for="(desc, i) of descs"
        class="desc wow animate__animated animate__fadeInUp text-center"
        :class="animationEntry"
        data-wow-duration="2s"
      >
        {{ desc }}
      </p>
      <div class="industries">
        <div
          v-for="(item, i) of features"
          :key="i"
          class="industries-item"
          :style="{
            position: 'relative',
            'padding-bottom': '100px',
          }"
        >
          <div
            :data-wow-duration="item.duration + 's'"
            class="industry wow animate__animated animate__fadeInUp !py-5"
            :class="noTitle === true ? 'industry-noTitle' : 'industry'"
          >
            <!-- <div v-if="item.serviceContract" class="right-top-area">
              {{ item.serviceContract }}
            </div> -->
            <div class="title" v-if="item.title">
              {{ item.title }}
            </div>
            <div class="mt-3">
              <img
                :src="item.icon"
                class="w-[180px]"
                style="width: 180px; height: 180px"
              />
            </div>

            <div class="subTitle">
              <div v-if="item.packageId == 0">
                {{ $t("online.minimum") }}
              </div>
              <div v-else>{{ $t("online.monthlyLowTo") }}</div>
              HKD
              <span style="font-weight: bold; font-size: 32px; color: #333333">
                {{ item.mop }}
              </span>
              / {{ $t("online.startingMonth") }}
            </div>
          </div>
          <div
            class="subTitle"
            style="
              background: #f3f3f3;
              border-radius: 5px;
              display: flex;
              justify-content: center;
              width: 90%;
              margin-left: 5%;
            "
          >
            <div class="flex justify-center align-center w-full py-2">
              <div class="mx-2 cursor-pointer">
                <a-popover placement="topLeft">
                  <template #content>
                    <div>
                      {{ $t("online.assistantManager") }}
                    </div>
                  </template>
                  <img :src="require('@/assets/img/teacher.svg')" class="w-[180px]" />
                </a-popover>
                <span v-if="item.people">
                  {{ item.people[0] }}
                </span>
              </div>
              <div class="mx-2 cursor-pointer">
                <a-popover placement="topLeft">
                  <template #content>
                    <div>
                      {{ $t("online.mentor") }}
                    </div>
                  </template>
                  <img :src="require('@/assets/img/one-two2.svg')" class="w-[180px]" />
                </a-popover>
                <span v-if="item.people">
                  {{ item.people[1] }}
                </span>
              </div>
              <div class="mx-2 cursor-pointer">
                <a-popover placement="topLeft">
                  <template #content>
                    <div>
                      {{ $t("online.numberClassesOpened") }}
                    </div>
                  </template>
                  <img :src="require('@/assets/img/black-ban.svg')" class="w-[180px]" />
                </a-popover>
                <span v-if="item.people">
                  {{ item.people[2] }}
                </span>
              </div>

              <div class="mx-2 cursor-pointer">
                <a-popover placement="topLeft">
                  <template #content>
                    <div>
                      {{ $t("online.freeDataCapacity") }}
                    </div>
                  </template>
                  <img :src="require('@/assets/img/content-3.svg')" class="w-[180px]" />
                </a-popover>
                <span v-if="item.people"> {{ item.people[3] }} G </span>
              </div>
            </div>
          </div>
          <!-- 下半 -->
          <div style="padding: 0 25px 25px 25px">
            <!-- 内容 -->
            <div style="margin-top: 20px" class="fixed-scroll-container">
              <a-collapse
                :bordered="false"
                v-model:activeKey="activeKey"
                accordion
                expandIconPosition="right"
              >
                <template v-for="(desc, index) in item.desc">
                  <div v-if="desc.haveLength == 0">
                    <div
                      style="
                        padding: 12px 16px;
                        padding-right: 40px;
                        display: flex;
                        background-color: white;
                      "
                    >
                      <div class="lower-desc-icon">
                        <img
                          :src="require('@/assets/img/green-yes.svg')"
                          class="w-[180px]"
                        />
                      </div>
                      <div class="ml-3" style="color: black">{{ desc.content }}</div>
                    </div>
                  </div>
                  <!-- 叉 -->
                  <div v-else-if="desc.have == 2">
                    <div
                      style="
                        padding: 12px 16px;
                        padding-right: 40px;
                        display: flex;
                        background-color: white;
                      "
                    >
                      <div class="lower-desc-icon">
                        <img
                          :src="require('@/assets/img/green-cha.svg')"
                          class="w-[180px]"
                        />
                      </div>
                      <div class="ml-3" style="color: black">{{ desc.content }}</div>
                    </div>
                  </div>
                  <!-- 有子内容 -->
                  <a-collapse-panel
                    v-else
                    class="customStyle"
                    style="background-color: #ffffff"
                    :key="index + item.title"
                    :header="desc.title"
                  >
                    <template #header>
                      <div style="display: flex; justify-content: space-between">
                        <div>
                          <img
                            v-if="desc.have == 0"
                            :src="require('@/assets/img/green-yuan.svg')"
                            class="w-[180px]"
                          />
                          <img
                            v-else-if="desc.have == 2"
                            :src="require('@/assets/img/green-cha.svg')"
                            class="w-[180px]"
                          />
                          <img
                            v-else
                            :src="require('@/assets/img/green-yes.svg')"
                            class="w-[180px]"
                          />

                          <span
                            class="ml-2"
                            :style="{ opacity: desc.have == 2 ? 0.5 : 1 }"
                          >
                            {{ desc.content }}
                          </span>
                        </div>
                        <div>
                          <span v-if="desc.haveLength != 0 && desc.haveLength != -1"
                            >{{ desc.haveLength }}
                          </span>
                        </div>
                      </div>
                    </template>
                    <div class="">
                      <div
                        v-if="desc.children"
                        style="
                          background-color: #f7f7f7;
                          border-radius: 8px;
                          padding: 10px 0;
                        "
                      >
                        <div
                          class="ml-5 mt-3 mb-3"
                          v-for="(theContent, index) in desc.children"
                          :key="index + item.title"
                          :style="{ opacity: theContent.have == 2 ? 0.5 : 1 }"
                        >
                          <!-- 0 是  0  ，1 是yes  2 是叉-->
                          <img
                            v-if="theContent.have == 0"
                            :src="require('@/assets/img/green-yuan.svg')"
                            class="w-[180px]"
                          />
                          <img
                            v-if="theContent.have == 1"
                            :src="require('@/assets/img/green-yes.svg')"
                            class="w-[180px]"
                          />
                          <img
                            v-if="theContent.have == 2"
                            :src="require('@/assets/img/green-cha.svg')"
                            class="w-[180px]"
                          />
                          <span class="ml-2" v-if="theContent.specialCase == 1">
                            {{ $t("onlineContent.chooseOne") }}
                            <div class="ml-8">
                              - {{ $t("onlineContent.standardCourses") }}
                            </div>
                            <div class="ml-8">
                              - {{ $t("onlineContent.elasticCourses") }}
                            </div>
                            <div class="ml-8">
                              - {{ $t("onlineContent.membershipCourses") }}
                            </div>
                          </span>
                          <span class="ml-2" v-else>
                            {{ theContent.content }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </a-collapse-panel>
                </template>
              </a-collapse>
            </div>
            <div class="btn-area">
              <div class="flex justify-center text-center mt-5 px-[5px] align-center">
                <div v-for="(iconItem, index) in item.icons" :key="index" class="mx-2">
                  <a-popover placement="top">
                    <template #content>
                      <div>
                        {{ iconItem.content }}
                      </div>
                    </template>
                    <div style="cursor: pointer">
                      <v-img :src="iconItem.name" max-width="30" />
                    </div>
                  </a-popover>
                </div>
              </div>
              <div class="flex justify-center text-center mt-5">
                <v-btn
                  rounded
                  outlined
                  large
                  dark
                  :style="{
                    width: '100%',
                  }"
                  class="subscribe-btn"
                  @click="jumpLeave(item.packageId, item.title)"
                >
                  <span class="" style="font-size: 16px">
                    {{ $t("online.subscribe") }}
                  </span>
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",

  data() {
    return {
      activeKey: "1",
    };
  },
  props: {
    coverColor: {
      type: String,
      default: "#ffffff",
    },
    descContentColor: {
      type: String,
      default: "#ffffff",
    },
    title: {
      type: String,
      default: "",
    },
    serviceContract: {
      type: String,
      default: "",
    },
    animationEntry: {
      type: String,
      default: "animate__fadeInUp",
    },
    descs: {
      type: Array,
    },
    features: {
      type: Array,
    },
    noTitle: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    jumpLeave(packageId, companyPackageName) {
      this.$router.push({
        path: "/leave_message",
        query: { packageId: packageId, companyPackageName: companyPackageName },
      });
    },
  },
  mounted() {
    new this.$wow.WOW().init();
  },
};
</script>

<style lang="scss" scoped>
@media (min-width: 640px) {
  .desc-content-item {
    width: 100%;
    height: auto;
    padding: 90px 200px;

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .com-title {
        font-size: 50px;
        // font-family: MicrosoftYaHei !important;
        color: #333333;
      }

      .desc {
        margin-top: 20px;
        font-size: 26px;
        // font-family: MicrosoftYaHei !important;
        color: #666;
      }
      .industries {
        display: grid;
        min-width: 100%;
        grid-gap: 25px 40px;
        min-width: 100%;
        grid-template-columns: repeat(4, 350px);
        margin-top: 50px;
        justify-content: center;

        .industries-item {
          box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.28);
          border-radius: 25px;
          border: 2px solid #dddddd;
          position: relative;
          max-width: 350px;
        }
        // grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        // grid-template-rows: repeat(auto-fit, minmax(350px, 1fr));
        // grid-template-rows: repeat(1, 380px);
        margin-top: 50px;
        .industry {
          position: relative;
          display: flex;
          align-items: center;
          flex-direction: column;
          border-radius: 20px;
          padding: 25px 25px 0 25px;
          text-align: center;
          .v-image {
            width: 60px !important;
            height: 60px !important;
          }
          .title {
            margin-top: 20px;
            font-weight: 500;
            text-align: center;
            font-size: 28px !important;
            height: 80px;
          }
          .desc {
            margin-top: 20px;
            font-size: 20px;
            color: #666;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            .desc-icon {
              margin-top: 10px;
              margin-right: 10px;
              .v-image {
                width: 20px !important;
                height: 20px !important;
              }
            }
          }
        }
        .industry-noTitle {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          border-radius: 20px;
          padding: 25px;
          box-shadow: 0px 5px 13px 0px rgba(5, 41, 77, 0.17);
          border: 1px solid #fdfdfd;
          .v-image {
            width: 100px !important;
            height: 100px !important;
          }
          .desc {
            margin-top: 20px;
            font-size: 26px;
            color: #666;
          }
        }
      }
    }
  }
  .btn-area {
    bottom: 30px;
    position: absolute;
    width: 300px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
  }
}
@media (max-width: 640px) {
  .desc-content-item {
    width: 100%;
    height: auto;
    padding: 50px 15px;
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .com-title {
        font-size: 25px;
        // font-family: MicrosoftYaHei !important;
        color: #333333;
      }
      .desc {
        margin-top: 10px;
        font-size: 16px;
        // font-family: MicrosoftYaHei !important;
        color: #666;
      }
      .industries {
        width: 100%;
        display: grid;
        grid-gap: 10px 30px;
        // grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
        // grid-template-rows: repeat(auto-fit, minmax(200px, 1fr));
        margin-top: 30px;
        .industries-item {
          box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.28);
          border-radius: 25px;
          border: 2px solid #dddddd;
          margin: 0 auto;
          width: 100%;
        }
        .industry {
          display: flex;
          align-items: center;
          flex-direction: column;
          border-radius: 20px;
          padding: 20px;
          .title {
            font-size: 24px !important;
            color: #333;
            font-weight: bold;
            margin-top: 10px;
          }
          .v-image {
            width: 35px !important;
            height: 35px !important;
          }
          .desc {
            margin-top: 5px;
            font-size: 12px;
            line-height: 16px;
            color: #666;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            .desc-icon {
              margin-top: 5px;
              margin-right: 5px;
              .v-image {
                width: 15px !important;
                height: 15px !important;
              }
            }
          }
        }
        .industry-noTitle {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          border-radius: 20px;
          padding: 20px;
          box-shadow: 0px 5px 13px 0px rgba(5, 41, 77, 0.17);
          border: 1px solid #fdfdfd;

          .v-image {
            width: 57px !important;
            height: 57px !important;
          }
          .desc {
            margin-top: 15px;
            font-size: 16px;
            line-height: 16px;
            color: #666;
            line-height: 26px;
          }
        }
      }
    }
  }
  .btn-area {
    bottom: 30px;
    position: absolute;
    text-align: center;
    width: 270px;
    left: 50%;
    transform: translateX(-50%);
  }
}
// new
.subTitle {
  margin-top: 20px;
  font-size: 20px;
  color: #666666;
  text-align: center;
  height: 80px;
}
.lower-desc {
  margin-top: 5px;
  font-size: 12px;
  line-height: 16px;
  color: #666;
  display: flex;
  justify-content: start;
  align-items: flex-start;
  // align-items: center;
  .lower-desc-icon {
    margin-top: 5px;
    margin-right: 5px;
    .v-image {
      width: 15px !important;
      height: 15px !important;
    }
  }
  .lower-desc-content {
    margin-left: 3px;
    margin-top: 10px;
    font-size: 18px;
  }
}
.right-top-area {
  position: absolute;
  right: 0;
  top: 0;
  background: #fedabd;
  color: #f18f1e;
  padding: 10px;
  border-radius: 0 10px 0 10px;
}
.flex {
  display: flex;
}
.subscribe-btn {
  background-color: #fff;
  border: 1px solid #ff870d;
  color: #ff870d;
  &:hover {
    background-color: #ff870d;
    color: #fff;
  }
}
.customStyle {
  background: #f7f7f7;
  border-radius: 4px;
  border: 0;
  border: none;
}
.ant-collapse-borderless > .ant-collapse-item {
  border: none;
}
.ant-collapse > .ant-collapse-item {
  border: none;
}
.fixed-scroll-container {
  height: 400px; /* 你可以根据需要设置高度 */
  overflow-y: auto; /* 当内容超出容器高度时显示垂直滚动条 */
}
/* 滚动条轨道样式 */
::-webkit-scrollbar {
  width: 8px; /* 垂直滚动条宽度 */
  height: 8px; /* 水平滚动条高度 */
  background-color: #f5f5f5; /* 轨道颜色 */
}

/* 滚动条滑块样式 */
::-webkit-scrollbar-thumb {
  background-color: #ccc; /* 滑块颜色 */
  border-radius: 4px; /* 滑块圆角 */
}

/* 滚动条滑块 hover 样式 */
::-webkit-scrollbar-thumb:hover {
  background-color: #aaa; /* 滑块 hover 时的颜色 */
}
::v-deep(.ant-collapse) {
  font-size: 16px;
}
</style>
