import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
// import VueYouTubeEmbed from "vue-youtube-embed";
import VueRouter from "vue-router";
import routes from "./router/index";
import i18n from "@/locales/index";
import store from "@/store/index";
import Antd from "ant-design-vue";
// import "ant-design-vue/dist/antd.css";
import 'ant-design-vue/dist/antd.less'
import "animate.css";
import wow from "wowjs";
import "wowjs/css/libs/animate.css";
import axios from "axios";
Vue.prototype.$wow = wow;
Vue.use(Antd);
Vue.use(VueRouter);
// Vue.use(VueYouTubeEmbed);
Vue.config.productionTip = false;
Vue.prototype.$http = axios;

// 生效
const scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition) {
    return savedPosition;
  } else {
    return { x: 0, y: 0 };
  }
};

const router = new VueRouter({
  mode: "history",
  scrollBehavior,
  routes: routes
});
new Vue({
  vuetify,
  router,
  i18n,
  store,
  render: (h) => h(App)
}).$mount("#app");
