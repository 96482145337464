<template>
  <div
    class="desc-content-item"
    :style="{
      background: coverColor,
    }"
  >
    <div class="content">
      <div class="wow animate__animated animate__fadeInUp com-title">
        {{ title }}
      </div>
      <p
        v-for="(desc, i) of descs"
        class="desc wow animate__animated animate__fadeInUp"
        :class="animationEntry"
        data-wow-duration="2s"
      >
        {{ desc }}
      </p>
      <div class="industries">
        <div
          v-for="(item, i) of features"
          :key="i"
          :data-wow-duration="item.duration + 's'"
          class="industry wow animate__animated animate__fadeInUp !py-5"
          :class="noTitle === true ? 'industry-noTitle' : 'industry'"
          :style="{
            backgroundColor: item.descColor,
          }"
        >
          <div>
            <v-img :src="item.icon" contain :max-width="item.iconSize" />
          </div>

          <div class="title" v-if="item.title">{{ item.title }}</div>
          <div class="desc" v-for="(desc, index) in item.desc" :key="index">
            <div class="desc-icon" v-if="noTitle === false">
              <v-img :src="desc.descIcon" contain max-width="20" />
            </div>
            <div class="desc-content">{{ desc.content }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    coverColor: {
      type: String,
      default: "#ffffff",
    },
    descContentColor: {
      type: String,
      default: "#ffffff",
    },
    title: {
      type: String,
      default: "",
    },
    animationEntry: {
      type: String,
      default: "animate__fadeInUp",
    },
    descs: {
      type: Array,
    },
    features: {
      type: Array,
    },
    noTitle: {
      type: Boolean,
      default: false,
    },
  },
  methods: {},
  mounted() {
    new this.$wow.WOW().init();
  },
};
</script>

<style lang="scss" scoped>
@media (min-width: 640px) {
  .desc-content-item {
    width: 100%;
    height: auto;
    padding: 90px 200px;

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .com-title {
        font-size: 50px;
        // font-family: MicrosoftYaHei !important;
        color: #333333;
      }

      .desc {
        margin-top: 20px;
        font-size: 26px;
        // font-family: MicrosoftYaHei !important;
        color: #666;
      }
      .industries {
        display: grid;
        grid-gap: 25px 33px;
        grid-template-columns: repeat(4, 320px);
        // grid-template-rows: repeat(1, 380px);
        margin-top: 50px;
        .industry {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          border-radius: 20px;
          padding: 25px;
          .v-image {
            width: 60px !important;
            height: 60px !important;
          }
          .title {
            font-size: 25px !important;
            flood-color: #333;
            font-weight: bold;
            margin-top: 20px;
            min-height: 64px;
          }
          .desc {
            margin-top: 20px;
            font-size: 20px;
            color: #666;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            .desc-icon {
              margin-top: 10px;
              margin-right: 10px;
              .v-image {
                width: 20px !important;
                height: 20px !important;
              }
            }
          }
        }
        .industry-noTitle {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          border-radius: 20px;
          padding: 25px;
          box-shadow: 0px 5px 13px 0px rgba(5,41,77,0.17);
          border: 1px solid #FDFDFD;
          .v-image {
            width: 100px !important;
            height: 100px !important;
          }
          .desc {
            margin-top: 20px;
            font-size: 26px;
            color: #666;
          }
        }
      }
    }
  }
}
@media (max-width: 640px) {
  .desc-content-item {
    width: 100%;
    height: auto;
    padding: 50px 15px;

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .com-title {
        font-size: 25px;
        // font-family: MicrosoftYaHei !important;
        color: #333333;
      }
      .desc {
        margin-top: 10px;
        font-size: 16px;
        // font-family: MicrosoftYaHei !important;
        color: #666;
      }
      .industries {
        display: grid;
        grid-gap: 10px 10px;
        grid-template-columns: repeat(2, 180px);
        grid-template-rows: repeat(2, auto);
        margin-top: 30px;
        .industry {
          // @apply flex items-center justify-center flex-col;
          display: flex;
          align-items: flex-start;
          // justify-content: center;
          flex-direction: column;
          border-radius: 20px;
          padding: 20px;
          // background-color: red;
          .title {
            font-size: 15px !important;
            color: #333;
            line-height: 20px;
            font-weight: bold;
            margin-top: 10px;
            min-height: 40px;
          }
          .v-image {
            width: 35px !important;
            height: 35px !important;
          }
          .desc {
            margin-top: 5px;
            font-size: 12px;
            line-height: 16px;
            color: #666;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            .desc-icon {
              margin-top: 5px;
              margin-right: 5px;
              .v-image {
                width: 15px !important;
                height: 15px !important;
              }
            }
          }
        }
        .industry-noTitle {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          border-radius: 20px;
          padding: 20px;
          box-shadow: 0px 5px 13px 0px rgba(5,41,77,0.17);
          border: 1px solid #FDFDFD;
    
          .v-image {
            width:57px !important;
            height: 57px !important;
          }
          .desc {
            margin-top: 15px;
            font-size: 16px;
            line-height: 16px;
            color: #666;
            line-height: 26px;
          }
        }
      }
    }
  }
}
</style>