/**
 * Independent time operation tool to facilitate subsequent switch to dayjs
 */
import dayjs, { Dayjs } from "dayjs";

const DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm";
const DATE_FORMAT = "YYYY-MM-DD";

export function formatToDateTime(date, format = DATE_TIME_FORMAT) {
  return dayjs(date).format(format);
}

export function formatToDate(date, format = DATE_FORMAT) {
  return dayjs(date).format(format);
}

// date object to timestamp
export function dateToTimestamp(date) {
  return dayjs(date).unix();
}

// timestamp to format date
export function timestampToFormat(time, format = DATE_FORMAT) {
  return dayjs(parseInt(time) * 1000).format(format);
}
// object to format
export function objectToFormat(time, format) {
  if (!time) {
    return "";
  }
  return time.format(format);
}

export function toDay() {
  return dayjs();
}
export function dateFormat(date, dateFormat) {
  if (date.includes("T")) {
    return dateFormat;
  } else {
    return dayjs(date, dateFormat);
  }
}
// 获取本月最后一天  YYYY-MM-DD
export function monthLastDay(year, month) {
  return dayjs(`${year}-${month.toString().padStart(2, "0")}`)
    .endOf("month")
    .format("YYYY-MM-DD HH:mm:ss");
}
// 将分钟转换为小时和分钟
export function convertMinutesToHoursAndMinutes(minutes) {
  const hours = Math.floor(minutes / 60); // 将分钟转换为小时
  const remainingMinutes = minutes % 60; // 取余得到剩余的分钟数
  return { hours, minutes: remainingMinutes };
}
//  离现在的时间 有多少天
export function remainingDays(date) {
  const timeDiff = new Date(date).getTime() - Date.now();
  const remainingDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
  return remainingDays;
}

// dayOfWeek
export function dayOfWeek(theday) {
  const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  return daysOfWeek[theday];
}

const ONE_SECONDS = 1000;
const ONE_MINUTES = ONE_SECONDS * 60;
const ONE_HOUR = ONE_MINUTES * 60;
const ONE_DAY = ONE_HOUR * 24;

export const formatDate = (dateString) => {
  // 将日期字符串分割成年、月、日
  const parts = dateString.split("-");
  const year = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1; // JavaScript中月份是从0开始的
  const day = parseInt(parts[2], 10);

  // 创建Date对象
  const date = new Date(year, month, day);

  // 获取日期的各个部分
  const dayOfMonth = date.getDate();
  const monthOfYear = date.getMonth() + 1; // 将月份转换为1-12

  // 将日期转换为所需的格式
  const formattedDate = `${dayOfMonth}月${monthOfYear}日`;

  return formattedDate;
};
export const disabledFutureDate = (current, unit, OpUnitType = "day") => {
  return current && current > dateUtil().endOf(unit);
};
// 返回成 初始状态
export const dateUtil = dayjs;
