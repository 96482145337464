<template>
  <div ref="scrollContainer">
    <v-navigation-drawer
      v-model="drawer"
      app
      temporary
      dark
      src="@/assets/img/bgDrawer.jpg"
    >
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <!-- <img v-if="scrollPosition == 0" src="@/assets/img/logo.svg" alt="Logo" /> -->
            <img src="@/assets/img/orange-logo.svg" alt="Logo" />
          </v-list-item-avatar>
        </v-list-item>
      </v-list>

      <v-divider />

      <v-list dense>
        <v-list-item
          v-for="(item, i) in phoneTitles"
          :key="i"
          link
          @click="jumpDemo(item.url, item.go)"
        >
          <v-list-item-content>
            <v-list-item-title class="subtitile-1">{{ item.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group v-if="isXs" :value="false">
          <template v-slot:activator>
            <v-list-item-title>{{ $t("field.industryApplications") }} </v-list-item-title>
          </template>
          <v-list-item
            v-for="(item, index) in Object.keys(industryApplicationConfig)"
            :key="index"
            :label="`app-${item}`"
            link
            @click="gotoDetails(item)"
          >
            <v-list-item-content>
              <v-list-item-title class="subtitile-1">{{
                $t(industryApplicationConfig[item].title)
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <!-- 下载App -->
        <!-- <v-list-group :value="false">
          <template v-slot:activator>
            <v-list-item-title>{{ $t("field.downloadAPP") }}</v-list-item-title>
          </template>
          <v-list-item v-for="(item, index) in downloadList" :key="index" link @click="openDownloadUrl(item)">
            <v-list-item-content>
              <v-list-item-title class="subtitile-1">{{ $t(item.text) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group> -->
        <!-- 联系我们 -->
        <!-- <v-list-item link @click="jumpDemo('/contact_us')">
          <v-list-item-content>
            <v-list-item-title class="subtitile-1">{{ $t("common.callOut") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
        <!-- 国际化 -->
        <v-list-group :value="false">
          <template v-slot:activator>
            <v-list-item-title>{{ localeLabel }} </v-list-item-title>
          </template>
          <v-list-item
            v-for="(item, index) in localeList"
            :key="index"
            :label="item.displayText + '2222'"
            link
            @click="changeLocale(item), reDemo()"
          >
            <v-list-item-content>
              <v-list-item-title class="subtitile-1">{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
    <a-drawer
      placement="top"
      :closable="false"
      :visible="topDrawerVisible"
      :mask="true"
      :mask-closable="true"
      :zIndex="4"
      height="337px"
      :getContainer="getContainer"
      @close="() => (topDrawerVisible = false)"
    >
      <div class="drawer-content">
        <div class="menu-list">
          <div class="title">{{ $t("content.yourIndustry") }}</div>
          <div
            v-for="(item, index) in Object.keys(industryApplicationConfig)"
            :key="index"
            :class="['menu-item', index >= 4 ? 'magrin-top-30' : '']"
            @click="gotoDetails(item)"
          >
            <div class="item-title">{{ $t(industryApplicationConfig[item].title) }}</div>
            <div class="item-subtitle">
              {{ $t(industryApplicationConfig[item].subtitle) }}
            </div>
          </div>
        </div>
      </div>
    </a-drawer>

    <v-app-bar
      app
      :color="color"
      :flat="flat"
      dark
      class="px-15"
      :class="{ expand: flat }"
    >
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        class="mr-5"
        style="margin-left: -70px"
        v-if="isXs"
      />
      <v-toolbar-title
        style="display: flex; cursor: pointer; margin-left: -20px"
        @click="jumpDemo('/')"
      >
        <div>
          <!-- <v-img v-if="scrollPosition == 0" src="@/assets/img/logo.svg" max-width="50px" /> -->
          <v-img src="@/assets/img/orange-logo.svg" max-width="50px" />
        </div>
        <div class="flex" style="margin-left: 20px">{{ $t("field.lelePlatform") }}</div>
      </v-toolbar-title>
      <v-spacer />
      <!-- 右侧 -->
      <div v-if="!isXs">
        <v-btn
          v-for="(item, i) in titles"
          :key="i"
          text
          @click="jumpDemo(item.url, item.go)"
          style="font-size: 14px"
          :class="item.id == demoName ? 'this-demo' : ''"
          :data-tag="item.id"
          @mouseenter="onMouseEnter"
        >
          <span class=" "> {{ item.name }}</span>
        </v-btn>
        <v-btn rounded outlined text @click="jumpDemo('/leave_message')">
          <span>{{ $t("content.appointmentDemonstration") }}</span>
        </v-btn>
        <!-- <v-btn rounded style="margin-left: 20px" text @click="openDownload()">
          <span>{{ $t("field.downloadAPP") }}</span>
        </v-btn>
        <v-btn style="margin-left: 20px" rounded outlined text @click="jumbLeave()">
          <span> {{ $t("common.callOut") }} </span>
        </v-btn>
        <v-btn style="margin-left: 20px" rounded outlined text @click="jumpDemo(0, 3)">
          <span> {{ $t("field.applyForTrial") }} </span>
        </v-btn> -->
        <v-btn text style="padding-top: 16px">
          <v-select
            class="no-underline"
            @change="changeLocale(select), reDemo()"
            v-model:value="select"
            :items="localeList"
            item-value="event"
            item-text="text"
            :label="localeLabel"
            persistent-hint
            return-object
            single-line
            style="width: 120px"
          ></v-select>
        </v-btn>
      </div>
    </v-app-bar>
    <download-dialog
      :openDownloadDialog="openDownloadDialog"
      @close="openDownloadDialog = false"
    ></download-dialog>
  </div>
</template>

<style scoped>
.v-toolbar {
  transition: 0.6s;
}
.expand {
  height: 80px !important;
  padding-top: 10px;
}
.language-switching {
  width: 120px;
}
.flex {
  display: flex;
  align-items: center;
}
.this-demo {
  &:before {
    opacity: 0.08;
  }
}
.no-underline {
  outline: none !important;
  margin-top: 8px;
  font-size: 14px;
}
:deep(.v-text-field > .v-input__control > .v-input__slot:before) {
  border-style: none !important;
}
:deep(.v-text-field > .v-input__control > .v-input__slot:after) {
  background-color: currentColor;
  border-color: currentColor;
  border-style: none;
  border-width: thin 0 thin 0;
  transform: scaleX(0);
}
:deep(.ant-drawer-body) {
  padding-top: 120px;
}
</style>
<style lang="scss" scoped>
.drawer-content {
  .menu-list {
    padding: 0 80px;
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: repeat(4, auto);
    justify-content: space-between;
    .title {
      grid-column: 1/5;
      font-weight: bold;
      font-size: 16px;
      color: #666666;
      line-height: 24px;
      margin-bottom: 20px;
    }

    .menu-item {
      cursor: pointer;
      // margin-top: 30px;
      &.magrin-top-30 {
        margin-top: 30px;
      }
      .item-title {
        font-weight: bold;
        font-size: 18px;
        color: #333333;
        line-height: 26px;
      }

      .item-subtitle {
        font-size: 16px;
        color: #333333;
        line-height: 25px;
      }
    }
  }
}
</style>
<script setup>
import { localeList } from "@/settings/localeSetting";
import { downloadList, getMobileOperatingSystem } from "@/hooks/web/userAgent.js";
import downloadDialog from "@/components/common/downloadDialog.vue";
import "mutationobserver-shim";
</script>
<script>
import industryApplicationConfig from "@/assets/config/industryApplicationConfig.json";
export default {
  data: () => ({
    drawer: null,
    isXs: false,
    select: [],
    isScrolling: false,
    scrollPosition: 0,
    openDownloadDialog: false,
    topDrawerVisible: false,
    industryApplicationConfig,
  }),
  props: {
    color: String,
    flat: Boolean,
    demoName: Number | String,
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.isScrolling = true;
      this.scrollPosition = window.scrollY || window.pageYOffset;
    },
    appendIcon(option) {
      return `<v-img src="@/assets/img/bottom-arrow.svg" max-width="50px" />`;
      return `<v-img src="@/assets/img/bottom-arrow.svg" max-width="50px" />`;
      // return `<v-img src="@/assets/img/bottom-arrow.svg" alt="custom icon" />`;
    },
    onResize() {
      this.isXs = window.innerWidth < 850;
    },
    goto(url) {
      // window.location.href = url;
      window.open(url, "_blank");
    },
    jumpDemo(url, go) {
      if (go == 1) {
        this.goto("https://66.training/admin/login");
      } else if (go == 2) {
        this.openDownloadDialog = true;
      } else if (go == 3) {
        this.goto("https://66.training/admin/register");
      } else if (url.indexOf("industry_applications") !== -1) {
        this.topDrawerVisible = true;
      } else {
        this.$router.push(url);
      }
    },
    gotoDetails(key) {
      this.topDrawerVisible = false;
      this.$router.push(`/industry_applications/${key}`);
      setTimeout(() => {
        this.$router.go(0);
      }, 0);
    },
    onMouseEnter(e) {
      if (e.target?.dataset?.tag === "7") {
        this.topDrawerVisible = true;
      } else {
        this.topDrawerVisible = false;
      }
    },
    getContainer() {
      return document.documentElement;
    },
    jumbLeave() {
      this.$router.push("/contact_us");
    },
    // 切换语言
    changeLocale(lang) {
      this.$store.commit("useLocaleStore/changeLocale", lang);
    },
    reDemo() {
      location.reload();
    },
    openDownload() {
      this.openDownloadDialog = true;
    },
    openDownloadUrl(item) {
      if (getMobileOperatingSystem() == "Android") {
        window.open(item.androidUrl);
      } else if (getMobileOperatingSystem() == "iOS") {
        window.open(item.iosUrl);
      } else {
        alert("错误");
      }
    },
  },
  computed: {
    count() {
      return this.$store.state.count;
    },
    localeLabel() {
      return (
        localeList.find((item) => item.event === this.$i18n.locale)?.displayText || ""
      );
    },
    phoneTitles() {
      let _this = this;
      let phoneTitles = [
        {
          id: 1,
          name: _this.$t("field.leleTrainingBar"),
          url: "/",
        },
        {
          id: 5,
          name: _this.$t("field.leleStore"),
          url: "/store",
        },
        {
          id: 7,
          name: _this.$t("field.industryApplications"),
          url: "/industry_applications",
        },
        {
          id: 6,
          name: _this.$t("content.appointmentDemonstration"),
          url: "/leave_message",
        },
      ];
      if (this.isXs) {
        phoneTitles = phoneTitles.filter((x) => x.id !== 7);
      }
      return phoneTitles;
    },
    titles() {
      let _this = this;
      let titles = [
        {
          id: 1,
          name: _this.$t("field.leleTrainingBar"),
          url: "/",
        },
        {
          id: 5,
          name: _this.$t("field.leleStore"),
          url: "/store",
        },
        {
          id: 7,
          name: _this.$t("field.industryApplications"),
          url: "/industry_applications",
        },
      ];
      return titles;
    },
  },
  watch: {
    isXs(value) {
      if (!value) {
        if (this.drawer) {
          this.drawer = false;
        }
      }
    },
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
    this.handleScroll();
    window.addEventListener("scroll", this.handleScroll, { passive: true });
    if (localStorage.getItem("vuex")) {
      this.$store.commit(
        "useLocaleStore/changeLocale",
        JSON.parse(localStorage.getItem("vuex"))?.useLocaleStore.locale
      );
    }
  },
};
</script>
