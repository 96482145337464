<template>
  <v-app>
    <navigation :color="transparent" :flat="true" />
    <out-homepage></out-homepage>

    <v-scale-transition>
      <v-btn fab v-show="fab" v-scroll="onScroll" dark fixed bottom right color="secondary" @click="toTop">
        <v-icon>mdi-arrow-up</v-icon>
      </v-btn>
    </v-scale-transition>
    <foote :info="info" />
  </v-app>
</template>

<script>
import { watch } from "vue";
import foote from "@/components/common/Footer01";
import navigation from "@/components/common/NavigationBar";
import contentImg from "@/components/common/contentImg";
import { useCountdown } from "@/hooks/web/useCountdown";
import { useRoute } from "vue-router";
import phoneNumberList from "@/hooks/phoneNumber";
import outHomepage from "@/views/leave_message/views/outHomepage";
import appointmentDemo from "@/views/leave_message/views/appointment";
const { surplusSecond, start: startCountdown, loading } = useCountdown(60);

export default {
  name: "App",
  components: {
    navigation,
    contentImg,
    foote,
    surplusSecond,
    loading,
    useRoute,
    outHomepage,
    appointmentDemo,
  },
  data: () => ({
    fab: null,
    color: "",
    flat: null,
    surplusSecond,
    phoneNumberList,
    loading,
    codeType: "",
    appointment: false,
  }),
  computed: {},
  created() {
    const top = window.pageYOffset || 0;
    if (top <= 60) {
      this.color = "transparent";
      this.flat = true;
    }
    this.info = true;
    const useRoute = this.$route;
    if (useRoute.query.appointment == 1) {
      this.appointment = true;
    }
  },

  watch: {
    fab(value) {
      if (value) {
        this.color = "secondary";
        this.flat = false;
      } else {
        this.color = "transparent";
        this.flat = true;
      }
    },
  },

  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "register" });
  },

  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 60;
    },

    toTop() {
      this.$vuetify.goTo(0);
    },
  },
};
</script>
<style>
.v-application ul {
  padding-left: 0 !important;
}
</style>
<style lang="scss" scoped>
::v-deep .ant-input-group-addon {
  padding: 0 !important;
}

::v-deep .has-error .ant-input-group-addon {
  border-color: #ffffff !important;
}

::v-deep .ant-input-group-addon {
  border-color: #ffffff !important;
}

.v-application .info {
  background-color: #fff !important;
}

::v-deep .ant-form-vertical .ant-form-item {
  margin-bottom: 0;
}
</style>
