import { ref } from "vue";

export const useCountdown = (second, option) => {
  const { interval = 1000, callback, onFinish } = option || {};
  const loading = ref(false);
  const finished = ref(false);
  const surplusSecond = ref(second);
  const timeId = ref();

  const start = () => {
    if (loading.value === false) {
      loading.value = true;
      finished.value = false;
      timeId.value = setInterval(() => {
        if (surplusSecond.value === 0) {
          loading.value = false;
          finished.value = true;
          surplusSecond.value = second;
          clearInterval(timeId.value);
          if (callback) {
            callback();
          }
          if (onFinish) {
            onFinish();
          }
          return;
        }

        if (callback) {
          callback();
        }
        surplusSecond.value -= interval / 1000;
      }, interval);
    }
  };
  const reset = () => {
    clearInterval(timeId.value);
    loading.value = false;
    finished.value = false;
    surplusSecond.value = second;
    start();
  };

  return {
    loading,
    finished,
    surplusSecond,
    start,
    reset
  };
};
