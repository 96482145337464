<template>
  <div
    class="class-hour-management flex items-center flex-col"
    style="
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 80px 0 0 0 !important;
    "
  >
    <div class="title wow animate__animated animate__fadeInUp" data-wow-duration="2s">
      <!-- {{ content.title }} -->
      <span style="color: #fe880a"> {{ $t("field.lele") }} </span>
      {{ $t("field.platform") }}
    </div>
    <div class="desc wow animate__animated animate__fadeInUp" data-wow-duration="4s">
      {{ content.desc }}
    </div>
    <div class="desc wow animate__animated animate__fadeInUp" data-wow-duration="4s">
      {{ content.content }}
    </div>
    <div class="btn wow animate__animated animate__fadeInUp" data-wow-duration="6s">
      <v-btn
        rounded
        outlined
        large
        dark
        :style="{
          'background-color': content.bottomColor,
          'border-color': content.bottomColor,
        }"
        @click="goto('https://66.training/admin/login')"
        class="mt-10"
      >
        <span class="" style="font-size: 16px">
          {{ $t("field.login") }}
        </span>
      </v-btn>
    </div>
    <div class="btn wow animate__animated animate__fadeInUp" data-wow-duration="6s">
      <v-btn
        rounded
        outlined
        large
        dark
        @click="jumbLeave()"
        :style="{
          'background-color': content.bottomColor,
          'border-color': content.bottomColor,
        }"
        class="mt-5"
      >
        <span class="mr-2" style="font-size: 16px">
          {{ content.btnText }}
        </span>
        <v-img :src="content.btnDirection" />
      </v-btn>
    </div>
    <div style="width: 300px; margin-top: 10px" class="">
      <img
        v-if="toVideo == false"
        :src="content.newImg"
        style="width: 100%; height: 100%; cursor: pointer"
        @click="
          () => {
            changeVideoPlayShow(false);
            toVideo = true;
          }
        "
      />
      <div style="width: 300px" v-if="toVideo == true">
        <video
          class="video-content"
          ref="videoExhibitRef"
          src="https://66.training/training_read_minio/other/171c1c8c-84d3-450a-87ff-a03ed8e91585.mp4"
          controls
          @play="changeVideoPlayShow(false)"
          @pause="changeVideoPlayShow(true)"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      toVideo: false,
      videoSwitch: true,
    };
  },
  props: {
    content: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    gotoNew(url) {
      window.open(url, "_blank");
    },
    jumbLeave() {
      this.$router.push("/leave_message");
      // this.gotoNew("https://66.training/admin/register");
    },
    goto(url) {
      window.location.href = url;
    },
    changeVideoPlayShow(type) {
      this.videoSwitch = type;
    },
  },
  mounted() {
    new this.$wow.WOW().init();
  },
};
</script>

<style lang="scss" scoped>
// * {
//   font-family: MicrosoftYaHei !important;
// }

@media (max-width: 640px) {
  .video-area {
    max-width: 300px;
    max-height: 200px;
  }
  .video-content {
    width: 100%;
    height: auto;
  }
  .class-hour-management {
    min-height: 650px;
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    height: auto !important;
    background-image: url("~@/assets/img/page-cover3.png");
    .title {
      font-size: 30px !important;
      line-height: 30px !important;
      color: #fff;
      font-weight: bold;
      margin-top: 30px;
    }

    .desc {
      margin-top: 10px;
      padding: 0 50px;
      // color: #666;
      color: #fff;
      // font-family: MicrosoftYaHei;
      font-size: 20px;
      text-align: center;
    }
    .card-title {
      font-size: 14px;
      width: 60px;
      text-align: center;
      margin-top: 10px;
    }
    .card-title-width {
      margin-top: 10px;
      font-size: 14px;
      width: 100px;
    }

    img {
      // width: 901px;
      // height: 718px;
      width: 100%;
      // height: auto;
      object-fit: contain;
      margin-top: 20px;
    }

    .features {
      display: grid;
      width: 100% !important;
      // 横向
      // grid-gap: 30px 20px;
      // grid-template-columns: 1fr;
      // grid-template-rows: repeat(1fr, 4);
      // 竖着
      display: flex;
      justify-content: space-evenly;
      align-items: center;

      .feature {
        @apply flex items-center;

        p {
          margin-bottom: 0;
        }
        // padding-right: 10px;
        width: 118px;
        // height: 73px;
        height: 130px;
        border-radius: 10px;
        background: #fff;
        box-shadow: 0 5px 8px 0 rgb(0 0 0 / 20%);
        color: #666;
        font-weight: bold;
        font-family: MicrosoftYaHei;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .v-image {
          width: 52px;
          height: 52px;
          margin-right: 20px;
          margin-left: 15px;
        }
      }
    }
  }
}

@media (min-width: 640px) {
  .class-hour-management {
    position: relative;
    // height: 1100px;
    padding-top: 60px !important;
    background-image: url("../../../assets/img/store-bg.png");
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    height: auto !important;
    .title {
      // color: #2d3f50 ;
      font-size: 60px !important;
      line-height: 79px !important;
      background: linear-gradient(
        to right,
        #f75c4a,
        #fe8e5c
      ); /*设置渐变的方向从左到右 颜色从ff0000到ffff00*/
      -webkit-background-clip: text; /*将设置的背景颜色限制在文字中*/
      -webkit-text-fill-color: transparent; /*给文字设置成透明*/
      font-weight: bold;
      // font-family: MicrosoftYaHei, MicrosoftYaHei !important;
    }

    .desc {
      margin-top: 20px;
      width: 800px;
      color: #666;
      // font-family: MicrosoftYaHei;
      font-size: 26px;
      text-align: center;
    }

    .card-title {
      margin-top: 20px;
      font-size: 20px;
      width: 80px;
    }

    .card-title-width {
      margin-top: 20px;
      font-size: 20px;
      width: 140px;
    }

    img {
      // width: 901px;
      height: 650px;
      width: 100%;
      // height: auto;
      object-fit: contain;
      margin-top: 20px;
    }

    .features {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: 60px;
      // grid-gap: 30px 20px;
      // grid-template-columns: 1fr 1fr;
      // grid-template-rows: 1fr 1fr;
      width: 800px;

      .feature {
        @apply flex items-center;
        p {
          margin-bottom: 0;
        }
        margin-right: 25px;
        width: 250px;
        height: 200px;
        border-radius: 10px;
        background: #fff;
        box-shadow: 0 5px 8px 0 rgb(0 0 0 / 20%);
        color: #666;
        font-family: MicrosoftYaHei;
        font-size: 20px;
        padding: 20px;
        font-weight: bold;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        .v-image {
          width: 70px;
          height: 70px;
          margin-right: 40px;
          margin-left: 30px;
        }
      }
    }
  }
}
</style>
