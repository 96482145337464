<template>
  <div class="poster-box">
    <div class="logo">
      <v-img src="@/assets/img/poster-logo.png" />
    </div>
    <div class="title1">{{ $t("content.developingClientsFromHongKongAndMacau") }}</div>
    <div class="title2">{{ $t("content.embraceTheOpportunitiesOfTheGreaterBayArea") }}</div>
    <div class="desc">{{ $t("content.assistTheGuangdongHongKongMacaoGreaterBayArea") }}</div>
    <div class="qrCode">
      <v-img src="@/assets/img/poster-code1.png" />
      <v-img src="@/assets/img/poster-code2.png" class="ml-20" />
    </div>
    <div class="btn">
      <v-btn rounded large dark @click="jumbContactUs()" style="background: linear-gradient(90deg, #ec6d2e 0%, #fcc75e 100%)">
        <span> {{ $t("common.callOut") }} </span>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    jumbContactUs() {
      this.$router.push("/contact_us");
    },
  },
  created(){
    console.log('this.$i18n.locale',this.$i18n.locale);
    console.log('this.$i18n $t("common.callOut") ', this.$t("field.personalizedMallDesign") );
  }
};
</script>

<style lang="scss" scoped>
.ml-20 {
  margin-left: 20px;
}

.poster-box {
  @media (max-width: 640px) {
    background-image: url("~@/assets/img/poster-bg-phone.png");
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    height: 100vh;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    text-align: center;
    .logo {
      width: 300px;
      margin-top: 30px;
    }
    .title1 {
      font-size: 40px;
      line-height: 50px;
      background: linear-gradient(to bottom, #fff 0%, #8eeeff 100%); /* 设置线性渐变背景 */
      -webkit-background-clip: text; /* 将背景裁剪到文本形状（兼容旧版WebKit浏览器）*/
      background-clip: text; /* 将背景裁剪到文本形状 */
      color: transparent; /* 设置文字颜色为透明，以便显示背景的渐变效果 */
      margin-top: 18px;
      font-weight: bold;
    }
    .title2 {
      font-size: 40px;
      font-weight: normal;
      line-height: 50px;
      background: linear-gradient(to bottom, #fff, #8eeeff); /* 设置线性渐变背景 */
      -webkit-background-clip: text; /* 将背景裁剪到文本形状（兼容旧版WebKit浏览器）*/
      background-clip: text; /* 将背景裁剪到文本形状 */
      color: transparent; /* 设置文字颜色为透明，以便显示背景的渐变效果 */
      margin-top: 11px;
    }
    .desc {
      color: #fff;
      font-size: 20px;
      line-height: 25px;
      text-shadow: 0px 15px 13px rgba(16, 94, 178, 0.21);
      margin-top: 11px;
    }
    .qrCode {
      margin-top: 32px;
      display: flex;
      align-items: center;
      .v-image {
        width: 90px;
        height: 90px;
      }
    }
    .btn {
      margin-top: 32px;
      .v-btn {
        height: 44px;
        width: 200px;
        border-radius: 38px;
        span {
          font-size: 24px;
          font-weight: bold;
        }
      }
    }
  }
  @media (min-width: 640px) {
    background-image: url("~@/assets/img/poster-bg-web.png");
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    height: 100vh;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    flex:wrap;
    text-align: center;
    .logo {
      margin-top: 80px;
      width: 500px;
    }
    .title1 {
      font-size: 80px;
      line-height: 100px;
      background: linear-gradient(to bottom, #fff 0%, #8eeeff 100%); /* 设置线性渐变背景 */
      -webkit-background-clip: text; /* 将背景裁剪到文本形状（兼容旧版WebKit浏览器）*/
      background-clip: text; /* 将背景裁剪到文本形状 */
      color: transparent; /* 设置文字颜色为透明，以便显示背景的渐变效果 */
      margin-top: 38px;
      font-weight: bold;
    }
    .title2 {
      font-size: 80px;
      font-weight: normal;
      line-height: 100px;
      background: linear-gradient(to bottom, #fff, #8eeeff); /* 设置线性渐变背景 */
      -webkit-background-clip: text; /* 将背景裁剪到文本形状（兼容旧版WebKit浏览器）*/
      background-clip: text; /* 将背景裁剪到文本形状 */
      color: transparent; /* 设置文字颜色为透明，以便显示背景的渐变效果 */
      margin-top: 20px;
    }
    .desc {
      color: #fff;
      font-size: 40px;
      line-height: 51px;
      text-shadow: 0px 15px 13px rgba(16, 94, 178, 0.21);
      margin-top: 20px;
    }
    .qrCode {
      margin-top: 53px;
      display: flex;
      align-items: center;
      .v-image {
        width: 145px;
        height: 145px;
      }
    }
    .btn {
      margin-top: 70px;
      width: 336px;
      height: 76px;
      .v-btn {
        height: 76px;
        width: 336px;
        border-radius: 38px;
        span {
          font-size: 41px;
          font-weight: bold;
        }
      }
    }
  }
}
</style>