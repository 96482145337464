<template>
  <v-app>
    <!-- 乐激购 -->
    <navigation :color="transparent" :flat="true" />
    <v-main class="pt-0">
      <store-content-list :content="descContent"></store-content-list>
      <shop-content-list
        :title="$t('field.marketingHelper')"
        :descs="[]"
        :features="[
          {
            title: $t('field.helpTheNewAndKeepTheOld'),
            content: $t('field.helpTheNewAndKeepTheOld'),
            icon: require('@/assets/img/good-helper-01.svg'),
            descColor: '#FFE8F7',
            iconSize: '50px',
            duration: 2,
            desc: [
              {
                descIcon: require('@/assets/img/hepler-desc-svg-pink.svg'),
                content: $t('content.pointsMall'),
              },
            ],
          },
          {
            title: $t('field.personalizedMallDesign'),
            content: $t('content.customBannerImg'),
            icon: require('@/assets/img/good-helper-02.svg'),
            descColor: '#F4F0FE',
            iconSize: '50px',
            duration: 4,
            desc: [
              {
                descIcon: require('@/assets/img/hepler-desc-svg-purple.svg'),
                content: $t('content.theMallSupportsPersonalizedDesign'),
              },
            ],
          },
          {
            title: $t('field.multipleTerminalUsage'),
            content: $t('content.customMallColor'),
            icon: require('@/assets/img/good-helper-03.svg'),
            descColor: '#FFF5EC',
            iconSize: '50px',
            duration: 6,
            desc: [
              {
                descIcon: require('@/assets/img/hepler-desc-svg-orange.svg'),
                content: $t('content.theMallSupportsH5'),
              },
            ],
          },
          {
            title: $t('field.bigDataAnalysis'),
            content: $t('content.customMallColor'),
            icon: require('@/assets/img/good-helper-04.svg'),
            descColor: '#EAF7EF',
            iconSize: '50px',
            duration: 8,
            desc: [
              {
                descIcon: require('@/assets/img/hepler-desc-svg-green.svg'),
                content: $t('content.multidimensionalAnalysis'),
              },
              {
                descIcon: require('@/assets/img/hepler-desc-svg-green.svg'),
                content: $t('content.accuratelyPushRelevantProductsAndServices'),
              },
            ],
          },
        ]"
      >
      </shop-content-list>
      <content-img
        :image="changeMarketingImg"
        :title="$t('field.marketingApplication')"
        :descs="[$t('content.goodHelper')]"
        animation-entry="animate__fadeIn"
        direction="LTR"
        :features="[
          {
            content: $t('content.multipleMarketingMethods'),
            icon: require('@/assets/img/multipleMarketingMethods-01.svg'),
            border: true,
          },
          {
            content: $t('content.promote'),
            icon: require('@/assets/img/multipleMarketingMethods-02.svg'),
            border: true,
          },
          {
            content: $t('content.canParticipateIn'),
            icon: require('@/assets/img/multipleMarketingMethods-03.svg'),
            border: true,
          },
        ]"
        :coverColor="'#fff'"
      ></content-img>
      <content-img
        :image="require('@/assets/img/mall-decoration.png')"
        :title="$t('field.mallDecorationFunction')"
        :descs="[$t('content.customMallStyle')]"
        animation-entry="animate__fadeIn"
        direction="RTL"
        :features="[
          {
            content: $t('content.supportHomeDecoration'),
            icon: require('@/assets/img/mall-decoration-01.svg'),
            border: true,
          },
          {
            content: $t('content.customBannerImg'),
            icon: require('@/assets/img/mall-decoration-02.svg'),
            border: true,
          },
          {
            content: $t('content.customMallColor'),
            icon: require('@/assets/img/mall-decoration-03.svg'),
            border: true,
          },
        ]"
        :coverColor="'linear-gradient( 90deg, #FEEFFE 0%, #E5F2FE 100%)'"
      ></content-img>
      <shop-content-list
        :title="$t('field.commodityManagement')"
        :descs="[$t('content.manageGoodsEasily')]"
        :noTitle="true"
        :features="[
          {
            icon: require('@/assets/img/commodityManagement-01.svg'),
            descColor: '#fff',
            iconSize: '100px',
            shadow: true,
            duration: 2,
            desc: [
              {
                content: $t('content.customProductCategories'),
              },
            ],
          },
          {
            icon: require('@/assets/img/commodityManagement-02.svg'),
            descColor: '#fff',
            iconSize: '100px',
            shadow: true,
            duration: 4,
            desc: [
              {
                content: $t('content.meetTheNeedsOfDifferentTypesOfGoods'),
              },
            ],
          },
          {
            icon: require('@/assets/img/commodityManagement-03.svg'),
            descColor: '#fff',
            iconSize: '100px',
            shadow: true,
            duration: 6,
            desc: [
              {
                content: $t('content.importGoodsWithOneClick'),
              },
            ],
          },
          {
            icon: require('@/assets/img/commodityManagement-04.svg'),
            descColor: '#fff',
            iconSize: '100px',
            shadow: true,
            duration: 8,
            desc: [
              {
                content: $t('content.productDetails'),
              },
            ],
          },
        ]"
      >
      </shop-content-list>
      <content-img
        :image="changePointsMallImg"
        :title="$t('field.dataAnalysis')"
        :descs="[$t('content.understandBusinessData')]"
        animation-entry="animate__fadeIn"
        direction="LTR"
        :features="[]"
        :coverColor="'linear-gradient( 90deg, #FEEFFE 0%, #E5F2FE 100%)'"
      ></content-img>
      <content-img
        :image="require('@/assets/img/points-mall.png')"
        :title="$t('field.pointsMall')"
        :descs="[$t('content.setUpApointRewardMechanism')]"
        animation-entry="animate__fadeIn"
        direction="RTL"
        :features="[]"
        :coverColor="'#fff'"
      ></content-img>
    </v-main>
    <v-scale-transition>
      <v-btn
        fab
        v-show="fab"
        v-scroll="onScroll"
        dark
        fixed
        bottom
        right
        color="secondary"
        @click="toTop"
      >
        <v-icon>mdi-arrow-up</v-icon>
      </v-btn>
    </v-scale-transition>
    <div class="mt"></div>
    <foote />
  </v-app>
</template>

<script>
import foote from "@/components/common/Footer01";
import navigation from "@/components/common/NavigationBar";
// import navigation from "../lele_store/components/NavigationBar.vue";
// import contentImg from "@/components/common/contentImg";
import contentImg from "./components/contentImg.vue";
import descContentList from "@/components/common/descContentList";
import shopContentList from "./components/shopContentList.vue";
import storeContentList from "@/components/common/storeContentList";

export default {
  name: "App",
  components: {
    navigation,
    contentImg,
    shopContentList,
    foote,
    storeContentList,
  },
  data: () => ({
    fab: null,
    color: "",
    flat: null,
  }),
  computed: {
    changeMarketingImg() {
      let nowLocale = JSON.parse(localStorage.getItem("vuex"))?.useLocaleStore.locale;
      if (nowLocale === "zh_CN") {
        return require("@/assets/img/marketing-application.png");
      } else if (nowLocale === "zh_TW") {
        return require("@/assets/img/marketing-application.png");
      } else if (nowLocale === "en") {
        return require("@/assets/img/marketing-application-en.png");
      } else {
        return require("@/assets/img/marketing-application.png");
      }
    },
    changePointsMallImg() {
      let nowLocale = JSON.parse(localStorage.getItem("vuex"))?.useLocaleStore.locale;
      if (nowLocale === "zh_CN") {
        return require("@/assets/img/data-analysis.png");
      } else if (nowLocale === "zh_TW") {
        return require("@/assets/img/data-analysis.png");
      } else if (nowLocale === "en") {
        return require("@/assets/img/data-analysis-en.png");
      } else {
        return require("@/assets/img/data-analysis.png");
      }
    },

    // coverData() {
    //   let _this = this;
    //   let coverData = {
    //     title: _this.$t("field.leleEducationAndTraining"),
    //     subTitle: _this.$t("content.customizedIntelligentManagementSystem"),
    //     btnText: _this.$t("field.applyForTrial"),
    //     direction: require("@/assets/img/right-arrow.svg"),
    //     coverImg: require("@/assets/img/train_bg.png"),
    //     bottomColor: "#ff870d",
    //     bottomCover: ""
    //   };
    //   return coverData;
    // },
    descContent() {
      let _this = this;
      let descContent = {
        title: _this.$t("field.leleStore"),
        desc: _this.$t("content.keepUpWithTheTimes"),
        btnText: _this.$t("field.registerForUse"),
        loginBtn: _this.$t("field.login"),
        twoBtn: _this.$t("field.joinMeFun"),
        btnDirection: require("@/assets/img/right-arrow.svg"),
        btnColor: "#ff870d",
        coverImg: require("@/assets/img/store-main.png"),
        features: [
          {
            icon: require("@/assets/img/store-main-01.svg"),
            title: _this.$t("field.electronicMallSystem"),
          },
          {
            icon: require("@/assets/img/store-main-02.svg"),
            title: _this.$t("field.educationAndTrainingInstitutionsManagementSystem"),
          },
          {
            icon: require("@/assets/img/store-main-03.svg"),
            title: _this.$t("field.variousSystemsDevelopmentCustomizedSolutions"),
          },
        ],
      };
      return descContent;
    },
  },
  created() {
    const top = window.pageYOffset || 0;
    if (top <= 60) {
      this.color = "transparent";
      this.flat = true;
    }
  },

  watch: {
    fab(value) {
      if (value) {
        this.color = "secondary";
        this.flat = false;
      } else {
        this.color = "transparent";
        this.flat = true;
      }
    },
  },

  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 60;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
  },
};
</script>

<style scoped>
.mt {
  @media (min-width: 640px) {
    margin-top: 100px;
  }
  @media (max-width: 640px) {
    margin-top: 70px;
  }
}
/* * {
  font-family: MicrosoftYaHei, MicrosoftYaHei !important;
} */
#box {
  width: 200px;
  height: 200px;
  background-color: red;
  position: absolute;
}
.v-main {
  /* background-image: url("~@/assets/img/bgMain.png"); */
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}
</style>
