var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('navigation',{attrs:{"color":_vm.transparent,"flat":true}}),_c('v-main',{staticClass:"pt-0"},[_c('store-content-list',{attrs:{"content":_vm.descContent}}),_c('shop-content-list',{attrs:{"title":_vm.$t('field.marketingHelper'),"descs":[],"features":[
          {
            title: _vm.$t('field.helpTheNewAndKeepTheOld'),
            content: _vm.$t('field.helpTheNewAndKeepTheOld'),
            icon: require('@/assets/img/good-helper-01.svg'),
            descColor: '#FFE8F7',
            iconSize: '50px',
            duration: 2,
            desc: [
              {
                descIcon: require('@/assets/img/hepler-desc-svg-pink.svg'),
                content: _vm.$t('content.pointsMall'),
              },
            ],
          },
          {
            title: _vm.$t('field.personalizedMallDesign'),
            content: _vm.$t('content.customBannerImg'),
            icon: require('@/assets/img/good-helper-02.svg'),
            descColor: '#F4F0FE',
            iconSize: '50px',
            duration: 4,
            desc: [
              {
                descIcon: require('@/assets/img/hepler-desc-svg-purple.svg'),
                content: _vm.$t('content.theMallSupportsPersonalizedDesign'),
              },
            ],
          },
          {
            title: _vm.$t('field.multipleTerminalUsage'),
            content: _vm.$t('content.customMallColor'),
            icon: require('@/assets/img/good-helper-03.svg'),
            descColor: '#FFF5EC',
            iconSize: '50px',
            duration: 6,
            desc: [
              {
                descIcon: require('@/assets/img/hepler-desc-svg-orange.svg'),
                content: _vm.$t('content.theMallSupportsH5'),
              },
            ],
          },
          {
            title: _vm.$t('field.bigDataAnalysis'),
            content: _vm.$t('content.customMallColor'),
            icon: require('@/assets/img/good-helper-04.svg'),
            descColor: '#EAF7EF',
            iconSize: '50px',
            duration: 8,
            desc: [
              {
                descIcon: require('@/assets/img/hepler-desc-svg-green.svg'),
                content: _vm.$t('content.multidimensionalAnalysis'),
              },
              {
                descIcon: require('@/assets/img/hepler-desc-svg-green.svg'),
                content: _vm.$t('content.accuratelyPushRelevantProductsAndServices'),
              },
            ],
          },
        ]}}),_c('content-img',{attrs:{"image":_vm.changeMarketingImg,"title":_vm.$t('field.marketingApplication'),"descs":[_vm.$t('content.goodHelper')],"animation-entry":"animate__fadeIn","direction":"LTR","features":[
          {
            content: _vm.$t('content.multipleMarketingMethods'),
            icon: require('@/assets/img/multipleMarketingMethods-01.svg'),
            border: true,
          },
          {
            content: _vm.$t('content.promote'),
            icon: require('@/assets/img/multipleMarketingMethods-02.svg'),
            border: true,
          },
          {
            content: _vm.$t('content.canParticipateIn'),
            icon: require('@/assets/img/multipleMarketingMethods-03.svg'),
            border: true,
          },
        ],"coverColor":'#fff'}}),_c('content-img',{attrs:{"image":require('@/assets/img/mall-decoration.png'),"title":_vm.$t('field.mallDecorationFunction'),"descs":[_vm.$t('content.customMallStyle')],"animation-entry":"animate__fadeIn","direction":"RTL","features":[
          {
            content: _vm.$t('content.supportHomeDecoration'),
            icon: require('@/assets/img/mall-decoration-01.svg'),
            border: true,
          },
          {
            content: _vm.$t('content.customBannerImg'),
            icon: require('@/assets/img/mall-decoration-02.svg'),
            border: true,
          },
          {
            content: _vm.$t('content.customMallColor'),
            icon: require('@/assets/img/mall-decoration-03.svg'),
            border: true,
          },
        ],"coverColor":'linear-gradient( 90deg, #FEEFFE 0%, #E5F2FE 100%)'}}),_c('shop-content-list',{attrs:{"title":_vm.$t('field.commodityManagement'),"descs":[_vm.$t('content.manageGoodsEasily')],"noTitle":true,"features":[
          {
            icon: require('@/assets/img/commodityManagement-01.svg'),
            descColor: '#fff',
            iconSize: '100px',
            shadow: true,
            duration: 2,
            desc: [
              {
                content: _vm.$t('content.customProductCategories'),
              },
            ],
          },
          {
            icon: require('@/assets/img/commodityManagement-02.svg'),
            descColor: '#fff',
            iconSize: '100px',
            shadow: true,
            duration: 4,
            desc: [
              {
                content: _vm.$t('content.meetTheNeedsOfDifferentTypesOfGoods'),
              },
            ],
          },
          {
            icon: require('@/assets/img/commodityManagement-03.svg'),
            descColor: '#fff',
            iconSize: '100px',
            shadow: true,
            duration: 6,
            desc: [
              {
                content: _vm.$t('content.importGoodsWithOneClick'),
              },
            ],
          },
          {
            icon: require('@/assets/img/commodityManagement-04.svg'),
            descColor: '#fff',
            iconSize: '100px',
            shadow: true,
            duration: 8,
            desc: [
              {
                content: _vm.$t('content.productDetails'),
              },
            ],
          },
        ]}}),_c('content-img',{attrs:{"image":_vm.changePointsMallImg,"title":_vm.$t('field.dataAnalysis'),"descs":[_vm.$t('content.understandBusinessData')],"animation-entry":"animate__fadeIn","direction":"LTR","features":[],"coverColor":'linear-gradient( 90deg, #FEEFFE 0%, #E5F2FE 100%)'}}),_c('content-img',{attrs:{"image":require('@/assets/img/points-mall.png'),"title":_vm.$t('field.pointsMall'),"descs":[_vm.$t('content.setUpApointRewardMechanism')],"animation-entry":"animate__fadeIn","direction":"RTL","features":[],"coverColor":'#fff'}})],1),_c('v-scale-transition',[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.fab),expression:"fab"},{name:"scroll",rawName:"v-scroll",value:(_vm.onScroll),expression:"onScroll"}],attrs:{"fab":"","dark":"","fixed":"","bottom":"","right":"","color":"secondary"},on:{"click":_vm.toTop}},[_c('v-icon',[_vm._v("mdi-arrow-up")])],1)],1),_c('div',{staticClass:"mt"}),_c('foote')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }