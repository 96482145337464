export const LOCALE = {
  EN_US: "en",
  ZH_CN: "zh_CN",
  ZH_TW: "zh_TW",
};
// locale list
export const localeList = [
  {
    displayText: "繁體中文",
    text: "English",
    event: LOCALE.EN_US,
  },
  {
    displayText: "English",
    text: "简体中文",
    event: LOCALE.ZH_CN,
  },
  {
    displayText: "English",
    text: "繁體中文",
    event: LOCALE.ZH_TW,
  },
];
