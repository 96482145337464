import { inject, provide, reactive } from "vue";

export function createContext(context, key, options = {}) {
  const { createProvider = false, native = false } = options;

  const state = reactive(context);

  !createProvider && provide(key, native ? context : state);

  return {
    state,
  };
}

export function useContext(key = Symbol(), defaultValue) {
  return inject(key, defaultValue || {});
}
