import train from "@/views/lele_train/index.vue";
import leaveMessage from "@/views/leave_message/index01.vue";
import contactUs from "@/views/contact_us/index01.vue";
import leleStore from "@/views/lele_store/index.vue";
import poster from "@/views/poster/index.vue";
import industryApplications from "@/views/industry_applications/index.vue";

const routes = [
  {
    path: "/",
    component: train,
  },
  {
    path: "/store",
    component: leleStore,
  },
  {
    path: "/leave_message",
    component: leaveMessage,
  },
  {
    path: "/contact_us",
    component: contactUs,
  },
  {
    path: "/ac01",
    component: poster,
  },
  {
    path: "/industry_applications/:appId",
    component: industryApplications,
    props: true
  },

  // children: [
  //   {
  //     path: "/路径1/设置路径2",
  //     component: Demo01
  //   }
  // ]
];

export default routes;
